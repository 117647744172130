import { gql, useQuery } from '@apollo/client';
import { App_GetVersion } from '@app/codegen';
import {
	authAtom,
	ScreenSizeEnum,
	useAppearance,
	useAppState,
	useAppStateRecoil,
	useAppTheme,
} from '@itrustcapital/ui';
import { NavigationContainer } from '@react-navigation/native';
import { LinkingOptions } from '@react-navigation/native/lib/typescript/src/types';
import { createStackNavigator } from '@react-navigation/stack';
import AppLoading from 'expo-app-loading';
import * as Linking from 'expo-linking';
import React from 'react';
import { useWindowDimensions } from 'react-native';

import { AppHeader } from './AppHeader';
import { AuthNavigation, AuthRoutes } from './Auth';
import { MainDrawerNavigation, MainDrawerRoutes } from './Main';
import packageJson from '../../package.json';

const APP_GET_VERSION = gql`
	query App_GetVersion {
		clientVersion {
			success
			errorMessage
			data {
				version
				environment
			}
		}
	}
`;

const prefix = Linking.createURL('/');

// @ts-expect-error
const linking: LinkingOptions = {
	prefixes: [prefix],
	enabled: true,
	config: {
		screens: {
			...AuthRoutes,
			...MainDrawerRoutes,
		},
	},
};

const Stack = createStackNavigator();

export function AppNavigation() {
	const loading = useAppState(true);
	const authState = useAppStateRecoil(authAtom);
	const theme = useAppTheme();
	const appearance = useAppearance();
	const { width } = useWindowDimensions();

	const versionQuery = useQuery<App_GetVersion>(APP_GET_VERSION, {
		fetchPolicy: 'network-only',
		pollInterval: 100000,
	});
	const version = versionQuery?.data?.clientVersion?.data?.version;

	React.useEffect(() => {
		const currentTimestamp = Date.now();
		const storedLastReload = localStorage.getItem('lastReload');
		const lastReload = storedLastReload ? Number(storedLastReload) : null;

		const reloadEnabled = !lastReload || currentTimestamp - lastReload > 60000;

		const [versionNum = 0, buildNum = 0] =
			version?.split('.')?.map((numStr) => Number(numStr)) || [];

		const [packageVersionNum = 0, packageBuildNum = 0] =
			packageJson.version?.split('.')?.map((numStr) => Number(numStr)) || [];

		const updateRequired =
			reloadEnabled &&
			(versionNum > packageVersionNum ||
				(versionNum === packageVersionNum && buildNum > packageBuildNum));

		if (updateRequired) {
			localStorage.setItem('lastReload', currentTimestamp.toString());
			window.location.reload();
		}
	}, [version]);

	React.useEffect(() => {
		setTimeout(() => {
			loading.set(false);
		}, 500);
	}, []);

	if (loading.get) {
		return <AppLoading />;
	}

	return (
		<NavigationContainer
			linking={linking}
			theme={{
				dark: appearance.dark,
				colors: {
					card: theme['background-basic-color-1'],
					background: theme['background-basic-color-2'],
					primary: theme['color-primary-500'],
					text: theme['text-basic-color'],
					border: theme['border-basic-color-2'],
					notification: theme['color-info-400'],
				},
			}}
		>
			<Stack.Navigator
				screenOptions={{
					headerShown: width >= ScreenSizeEnum.lg,
					header: () => <AppHeader />,
				}}
			>
				<Stack.Screen
					component={AuthNavigation}
					name="AuthNavigation"
					options={{
						header: () => null,
					}}
				/>
				{authState.get.isAuthenticated && (
					<>
						<Stack.Screen
							component={MainDrawerNavigation}
							name="MainDrawerNavigation"
						/>
					</>
				)}
			</Stack.Navigator>
		</NavigationContainer>
	);
}
