import { gql, useQuery } from '@apollo/client';
import {
	SharedAtom_GetUserDetails,
	SharedAtom_GetUserDetails_users_nodes,
	SharedAtom_GetUserDetailsVariables,
} from '@app/codegen';
import { useAppStateRecoil } from '@itrustcapital/ui';
import React from 'react';
import { atom } from 'recoil';

export type UserState = {
	user?: SharedAtom_GetUserDetails_users_nodes | null;
	loading: boolean;
	refetch: () => void;
};

export const UserAtom = atom<UserState>({
	key: 'userAtom',
	default: {
		user: null,
		loading: false,
		refetch: () => {},
	},
	dangerouslyAllowMutability: false,
});

export const SHARED_ATOM_GET_USER_DETAILS = gql`
	query SharedAtom_GetUserDetails($where: UserFilterInput!) {
		users(where: $where, first: 1) {
			nodes {
				id
				firstName
				middleName
				lastName
				fullName
				priorNames
				email
				dateOfBirth
				socialSecurityNumber
				addresses {
					address
					address2
					city
					state
					zipCode
					type
				}
				phone
				employerName
				employmentStatus
				jobTitle
				status
				role
				kycStatus
				accounts {
					id
					status
					stage
					fundedAt
					wizardCompletedAt
					inboundFundings {
						id
					}
				}
			}
		}
	}
`;

export const useUserAtom = (id: number): UserState => {
	const userAtomState = useAppStateRecoil(UserAtom);
	const userQuery = useQuery<SharedAtom_GetUserDetails, SharedAtom_GetUserDetailsVariables>(
		SHARED_ATOM_GET_USER_DETAILS,
		{
			variables: {
				where: { id: { eq: id } },
			},
			skip: !id,
		}
	);

	const [user] = userQuery.data?.users?.nodes || [];

	React.useEffect(() => {
		userQuery.refetch();
	}, [id]);

	React.useEffect(() => {
		userAtomState.set({
			...userAtomState.get,
			loading: userQuery.loading,
		});
	}, [userQuery.loading]);

	React.useEffect(() => {
		userAtomState.set({
			...userAtomState.get,
			user,
		});
	}, [userQuery.data]);

	React.useEffect(() => {
		userAtomState.set({
			...userAtomState.get,
			refetch: userQuery.refetch,
		});
	}, [userQuery.refetch]);

	return userAtomState.get;
};
