import { ApolloError, gql, useMutation, useQuery } from '@apollo/client';
import {
	DocumentStage,
	DocumentStatus,
	DocumentType,
	DocuSignTemplateType,
	FileTemplateType,
	OrganismsDcGetDocuments_documents_nodes as DocumentNode,
	OrganismsVdGenerateDocumentPreviewUrl,
	OrganismsVdGenerateDocumentPreviewUrlVariables,
	OrganismsVdGenerateDocumentSignerUrl,
	OrganismsVdGenerateDocumentSignerUrlVariables,
	OrganismsVdGetDocument,
	OrganismsVdGetDocument_documents_nodes_files,
	OrganismsVdGetDocumentVariables,
	OrganismsVdGetUploadToken,
	OrganismsVdGetUploadTokenVariables,
	OrganismsVdSendDocument,
	OrganismsVdSendDocumentVariables,
	OrganismsVdUpdateDocumentFile,
	OrganismsVdUpdateDocumentFileVariables,
} from '@app/codegen';
import {
	CustomCard,
	CustomDataTable,
	DangerAssetSVG,
	ITrustAction,
	ITrustColumn,
} from '@app/shared/components';
import { DocumentSuccess } from '@app/shared/models';
import {
	AppAlert,
	AppIcon,
	AppModal,
	AppModalProps,
	datePickerMaxDate,
	datePickerMinDate,
	datePickerService,
	dateTimePlaceholder,
	getFileType,
	getFileUriBlob,
	getUploadUrl,
	humanize,
	localDateTimeFormat,
	ScreenSizeEnum,
	uploadFileAsync,
	useAppDevice,
	useAppState,
	useAppTheme,
} from '@itrustcapital/ui';
import { Button, Datepicker, Input, Spinner, Text } from '@ui-kitten/components';
import { formatDistanceToNow } from 'date-fns';
import * as DocumentPicker from 'expo-document-picker';
import { Linking, StyleSheet, View } from 'react-native';
import Toast from 'react-native-toast-message';

declare const window: any;

export const ORGANISMS_VD_GET_DOCUMENT = gql`
	query OrganismsVdGetDocument($where: DocumentFilterInput!) {
		documents(where: $where, first: 1) {
			nodes {
				id
				name
				fileTemplate
				docuSignTemplate
				status
				type
				createdAt
				stage
				sentAt
				signedAt
				files {
					id
					url
					name
					createdAt
				}
			}
		}
	}
`;

export const ORGANISMS_VD_SEND_DOCUMENT = gql`
	mutation OrganismsVdSendDocument($input: SendDocumentInput!) {
		sendDocument(input: $input) {
			success
			errorMessage
			data {
				id
				lockedUntil
			}
		}
	}
`;

export const ORGANISMS_VD_GENERATE_DOCUMENT_SIGNER_URL = gql`
	mutation OrganismsVdGenerateDocumentSignerUrl($input: GenerateDocumentSignerUrlInput) {
		generateDocumentSignerUrl(input: $input) {
			success
			errorMessage
			data {
				url
			}
		}
	}
`;

export const ORGANISMS_VD_GET_UPLOAD_TOKEN = gql`
	mutation OrganismsVdGetUploadToken($input: [GenerateDocumentUploadTokenInput]!) {
		generateDocumentUploadToken(input: $input) {
			data {
				uploadSasTokens
			}
		}
	}
`;

export const ORGANISMS_VD_UPDATE_DOCUMENT_FILE = gql`
	mutation OrganismsVdUpdateDocumentFile($input: UpdateDocumentInput!) {
		updateDocument(input: $input) {
			success
			errorMessage
		}
	}
`;

export const ORGANISMS_VD_GENERATE_DOCUMENT_PREVIEW_URL = gql`
	mutation OrganismsVdGenerateDocumentPreviewUrl($input: GenerateDocumentPreviewUrlInput!) {
		generateDocumentPreviewUrl(input: $input) {
			success
			errorMessage
			data {
				url
			}
		}
	}
`;

export interface ViewDocumentProps extends AppModalProps {
	document: DocumentNode | null;
	visible: boolean;
	toggleVisible: () => void;
	onSend: (success: boolean) => void;
	onUpdateName: () => void;
	refetchDocuments: () => void;
}

export function ViewDocument(props: ViewDocumentProps) {
	const styles = useCustomStyles();
	const uploadFileVisible = useAppState(false);
	const uploadFileResult = useAppState<DocumentSuccess | null>(null);
	const documentLocked = useAppState<false | string>(false);
	const newName = useAppState<string>(FileTemplateType.OTHER);
	const sendButtonText =
		props.document?.type === DocumentType.DOCU_SIGN ? 'Send Docusign' : 'Send Notice';
	const sendButtonDisabled =
		props?.document?.stage !== DocumentStage.DRAFT ||
		props.document?.status === DocumentStatus.APPROVED ||
		props.document?.status === DocumentStatus.DECLINED ||
		props.document?.status === DocumentStatus.VOIDED;

	const signButtonDisabled =
		(props.document?.docuSignTemplate === DocuSignTemplateType.DISTRIBUTION &&
			props.document?.stage !== DocumentStage.SIGNED) ||
		(props.document?.docuSignTemplate !== DocuSignTemplateType.DISTRIBUTION &&
			props.document?.stage !== DocumentStage.SENT_TO_CUSTODIAN);

	const getDocumentQuery = useQuery<OrganismsVdGetDocument, OrganismsVdGetDocumentVariables>(
		ORGANISMS_VD_GET_DOCUMENT,
		{
			fetchPolicy: 'no-cache',
			onCompleted: (data) => {
				const [doc] = data.documents?.nodes || [];

				if (doc?.fileTemplate === FileTemplateType.OTHER) {
					newName.set(doc?.name || FileTemplateType.OTHER);
				}
			},
			variables: {
				where: {
					id: {
						eq: props.document?.id!,
					},
				},
			},
			skip: !props.document?.id,
		}
	);

	const [document] = getDocumentQuery.data?.documents?.nodes || [];

	const [onGenerateSignerUrl, sendGenerateSignerUrlMutation] = useMutation<
		OrganismsVdGenerateDocumentSignerUrl,
		OrganismsVdGenerateDocumentSignerUrlVariables
	>(ORGANISMS_VD_GENERATE_DOCUMENT_SIGNER_URL, {
		onError,
		onCompleted: async (data) => {
			if (data.generateDocumentSignerUrl?.data?.url) {
				await Linking.openURL(data.generateDocumentSignerUrl?.data?.url);
			} else {
				Toast.show({
					type: 'error',
					text2: 'Cannot get signing url.',
				});
			}
		},
	});

	const [onGeneratePreviewUrl, generatePreviewUrlMutation] = useMutation<
		OrganismsVdGenerateDocumentPreviewUrl,
		OrganismsVdGenerateDocumentPreviewUrlVariables
	>(ORGANISMS_VD_GENERATE_DOCUMENT_PREVIEW_URL, {
		onError: () =>
			Toast.show({
				type: 'error',
				text2: 'Failed to generate preview url',
			}),
		refetchQueries: [ORGANISMS_VD_GET_DOCUMENT],
	});

	const [onSendDocument, sendDocumentMutation] = useMutation<
		OrganismsVdSendDocument,
		OrganismsVdSendDocumentVariables
	>(ORGANISMS_VD_SEND_DOCUMENT, {
		refetchQueries: [ORGANISMS_VD_GET_DOCUMENT],
	});

	const [onGetUploadToken, getUploadTokenMutation] = useMutation<
		OrganismsVdGetUploadToken,
		OrganismsVdGetUploadTokenVariables
	>(ORGANISMS_VD_GET_UPLOAD_TOKEN);

	const [onUpdateDocument, updateDocumentMutation] = useMutation<
		OrganismsVdUpdateDocumentFile,
		OrganismsVdUpdateDocumentFileVariables
	>(ORGANISMS_VD_UPDATE_DOCUMENT_FILE, {
		onError,
		refetchQueries: [ORGANISMS_VD_GET_DOCUMENT],
	});

	const isTemplateDocusign = Object.values(DocuSignTemplateType).includes(
		document?.docuSignTemplate as DocuSignTemplateType
	);

	function onClose(): void {
		uploadFileVisible.set(false);
		uploadFileResult.set(null);
		props.toggleVisible();
	}

	function onError(err: ApolloError): void {
		Toast.show({
			type: 'error',
			text2: err.message,
		});
	}

	async function handleSendDocument(documentId: number): Promise<void> {
		try {
			const response = await onSendDocument({
				variables: {
					input: {
						id: documentId,
					},
				},
			});

			if (response.data?.sendDocument?.success) {
				if (response.data?.sendDocument?.data?.lockedUntil) {
					documentLocked.set(response.data?.sendDocument?.data?.lockedUntil);
				} else {
					getDocumentQuery.refetch?.();
					props.onSend(true);
				}
			}
		} catch (err) {
			props.onSend(false);
		}
	}

	async function onSelectFile(): Promise<void> {
		const result = await DocumentPicker.getDocumentAsync();

		if (result.type === 'success') {
			uploadFileResult.set(result);
		}
	}

	async function onSaveFile(): Promise<void> {
		// TODO: uploadFileAsync doesn't work locally
		// if ((appsettings.version !== 'local' && !appDevice.isWeb) || appDevice.isNativeMobile) {
		try {
			const template = props.document?.fileTemplate;
			const type = props.document?.type;
			const id = props.document?.id;

			if (!template || !type || !id) {
				return;
			}

			if (uploadFileResult.get) {
				const extension = getFileType(uploadFileResult.get.uri!);
				const fileUploadToken = await onGetUploadToken({
					variables: {
						input: [
							{
								template,
								type,
								extension,
							},
						],
					},
				});

				const fileBlob = await getFileUriBlob(uploadFileResult.get.uri);

				const uploadUrl = await uploadFileAsync(
					fileBlob,
					fileUploadToken.data?.generateDocumentUploadToken?.data?.uploadSasTokens?.[0]!
				);

				await onUpdateDocument({
					variables: {
						input: {
							id,
							files: [
								{
									id: 0,
									url: getUploadUrl(uploadUrl),
									name: uploadFileResult.get.name,
								},
							],
						},
					},
				});

				getDocumentQuery.refetch?.();
				props.refetchDocuments();
				uploadFileVisible.set(false);
				Toast.show({
					type: 'success',
					text1: 'Document uploaded',
				});
			}
		} catch (err) {
			if (err instanceof ApolloError) {
				Toast.show({
					type: 'error',
					text1: err.name,
					text2: err.message,
				});
			}
		}
	}

	const handleRenameDocument = async () => {
		if (document?.id) {
			await onUpdateDocument({
				variables: {
					input: {
						id: document?.id,
						name: newName.get,
					},
				},
			});
			await getDocumentQuery.refetch();
			props.onUpdateName();
		}
	};

	const fileUploadActions: ITrustAction<OrganismsVdGetDocument_documents_nodes_files>[] = [
		{
			iconLib: generatePreviewUrlMutation.loading ? 'fa' : 'ion',
			iconName: generatePreviewUrlMutation.loading ? 'spinner' : 'eye',
			isDisabled: () =>
				!!generatePreviewUrlMutation.loading ||
				(props.document?.stage === DocumentStage.DRAFT &&
					props.document?.type === DocumentType.DOCU_SIGN),
			handler: async (file) => {
				const response = await onGeneratePreviewUrl({
					variables: {
						input: {
							documentFileId: file?.id,
							returnUrl: window.location.href,
						},
					},
				});

				if (response.data?.generateDocumentPreviewUrl?.data?.url) {
					window.open(response.data?.generateDocumentPreviewUrl?.data?.url, '_blank');
				}
			},
			name: 'View',
		},
	];

	const fileUploadColumns: ITrustColumn<OrganismsVdGetDocument_documents_nodes_files>[] = [
		{
			key: 'name',
			name: 'Name',
			selector: (file) => file?.name,
		},
		{
			key: 'createdAt',
			name: 'Date',
			maxWidth: 200,
			sortable: false,
			selector: (file) => localDateTimeFormat(file?.createdAt),
		},
	];

	return (
		<AppModal visible={props.visible}>
			<CustomCard
				hasCloseButton
				handleClosePress={onClose}
				header="View Document"
				loading={getDocumentQuery.loading}
				style={styles.main.container}
			>
				<View style={styles.inputs.row}>
					<View style={styles.inputs.control}>
						<Input
							disabled={document?.fileTemplate !== FileTemplateType.OTHER}
							label="Document Template"
							value={
								document?.fileTemplate === FileTemplateType.OTHER
									? newName.get
									: humanize(
											document?.fileTemplate ||
												document?.docuSignTemplate ||
												''
									  )
							}
							onChangeText={(text) => newName.set(text)}
						/>
					</View>
					{document?.fileTemplate === FileTemplateType.OTHER && (
						<View style={styles.inputs.renameButton}>
							<Button
								disabled={!newName.get || document?.name === newName.get}
								onPress={handleRenameDocument}
							>
								SAVE NAME
							</Button>
						</View>
					)}
				</View>
				<View style={styles.inputs.row}>
					<Input
						disabled
						label="Status"
						style={styles.inputs.control}
						value={humanize(document?.status || '')}
					/>

					<Input
						disabled
						label="Type"
						style={styles.inputs.control}
						value={humanize(document?.type || '')}
					/>

					<Input
						disabled
						label="Requires Signature"
						style={styles.inputs.control}
						value={isTemplateDocusign ? 'Yes' : 'No'}
					/>

					<View style={styles.inputs.control}>
						<Datepicker
							disabled
							date={document?.createdAt && new Date(document?.createdAt)}
							dateService={datePickerService}
							label="Created Date"
							max={datePickerMaxDate}
							min={datePickerMinDate}
							placeholder={dateTimePlaceholder}
						/>
					</View>
				</View>
				{props.document?.type === DocumentType.DOCU_SIGN && (
					<View style={styles.inputs.row}>
						<View style={styles.inputs.control}>
							<Datepicker
								disabled
								date={document?.sentAt && new Date(document?.sentAt)}
								dateService={datePickerService}
								label="Sent Date"
								max={datePickerMaxDate}
								min={datePickerMinDate}
								placeholder={dateTimePlaceholder}
							/>
						</View>
						<View style={styles.inputs.control}>
							<Datepicker
								disabled
								date={document?.signedAt && new Date(document?.signedAt)}
								dateService={datePickerService}
								label="Signed Date"
								max={datePickerMaxDate}
								min={datePickerMinDate}
								placeholder={dateTimePlaceholder}
							/>
						</View>
						{/* TODO: REQUIRED not sure what this is */}
						<View style={styles.inputs.control}>
							<Input disabled label="Action Required" value="Send docusign" />
						</View>
					</View>
				)}

				{/* DISPLAYS ALL FILES ATTACHED */}
				<CustomDataTable
					inModal
					columns={fileUploadColumns}
					data={document?.files! as OrganismsVdGetDocument_documents_nodes_files[]}
					paginationRowsPerPageOptions={[5, 10, 15, 20]}
					rowActions={fileUploadActions}
				/>

				{/* Actions Row before table */}
				<View style={styles.inputs.bottomRow}>
					{(document?.stage === DocumentStage.DRAFT ||
						document?.stage === DocumentStage.PENDING_APPROVAL) &&
						document?.type !== DocumentType.DOCU_SIGN && (
							<Button
								accessoryLeft={() =>
									uploadFileVisible.get ? (
										<AppIcon lib="fe" name="chevron-up" />
									) : (
										<AppIcon lib="fe" name="chevron-down" />
									)
								}
								size="small"
								status="basic"
								onPress={() => uploadFileVisible.set(!uploadFileVisible.get)}
							>
								Upload File
							</Button>
						)}
					<View style={styles.main.empty} />
					<View style={styles.inputs.actionsRow}>
						<Button
							disabled={signButtonDisabled || sendGenerateSignerUrlMutation.loading}
							style={[styles.actions.leftButton, styles.actions.button]}
							onPress={async () => {
								if (!sendGenerateSignerUrlMutation.loading) {
									await onGenerateSignerUrl({
										variables: {
											input: {
												id: props.document?.id!,
												returnUrl: window.location.href,
											},
										},
									});
								}
							}}
						>
							{sendGenerateSignerUrlMutation.loading ? (
								<View>
									<Spinner size="tiny" status="basic" />
								</View>
							) : (
								'Sign Document'
							)}
						</Button>

						<Button
							disabled={sendButtonDisabled}
							style={styles.actions.button}
							onPress={() =>
								!sendDocumentMutation.loading &&
								handleSendDocument(props.document?.id!)
							}
						>
							{sendDocumentMutation.loading ? (
								<View>
									<Spinner size="tiny" status="basic" />
								</View>
							) : (
								sendButtonText
							)}
						</Button>
					</View>
				</View>
				{/* End of actions row */}

				{/* Upload file section */}
				{uploadFileVisible.get && (
					<View>
						<View style={styles.fileUpload.fileContainer}>
							<Text>
								{uploadFileResult.get
									? uploadFileResult.get.name
									: 'Insert file here'}
							</Text>
						</View>
						<View style={styles.fileUpload.buttons}>
							<Button style={styles.fileUpload.selectButton} onPress={onSelectFile}>
								Select File
							</Button>
							<Button disabled={!uploadFileResult.get} onPress={onSaveFile}>
								{updateDocumentMutation.loading ||
								getUploadTokenMutation.loading ? (
									<View>
										<Spinner size="tiny" status="basic" />
									</View>
								) : (
									'Save'
								)}
							</Button>
						</View>
					</View>
				)}
				{/* End of upload file section */}
			</CustomCard>
			<AppAlert
				actions={[
					{
						title: 'OK',
						status: 'danger',
						appearance: 'filled',
						onPress: () => {
							documentLocked.set(false);
						},
					},
				]}
				message={
					documentLocked.get
						? `Document is currently being viewed/signed by client/user. Please check back in ${formatDistanceToNow(
								new Date(documentLocked.get)
						  )}.`
						: ''
				}
				title="Request Failed"
				visible={!!documentLocked.get}
				xmlIcon={DangerAssetSVG}
			/>
		</AppModal>
	);
}

function useCustomStyles() {
	const theme = useAppTheme();
	const appDevice = useAppDevice();

	const isScreenMd = appDevice.width >= ScreenSizeEnum.md;

	return {
		main: StyleSheet.create({
			container: {
				minWidth: 1000,
				maxHeight: '70%',
				overflow: 'scroll',
			},
			header: {
				borderBottomWidth: 1,
				borderBottomColor: theme['border-basic-color-3'],
				paddingBottom: 16,
				paddingHorizontal: 8,
				fontSize: 16,
			},
			content: {
				paddingHorizontal: 16,
				paddingTop: 12,
			},
			empty: {
				flex: 1,
			},
			view: {
				color: theme['text-info-active-color'],
				textDecorationLine: 'underline',
				textDecorationStyle: 'solid',
				marginLeft: 8,
			},
			files: {
				paddingHorizontal: 8,
			},
		}),
		inputs: StyleSheet.create({
			row: {
				flexDirection: isScreenMd ? 'row' : 'column',
				justifyContent: isScreenMd ? 'space-between' : 'flex-start',
				alignItems: isScreenMd ? 'center' : 'stretch',
				flexWrap: isScreenMd ? 'nowrap' : 'wrap',
				marginBottom: 8,
			},
			control: {
				flex: 1,
				paddingHorizontal: 8,
			},
			bottomRow: {
				flexDirection: 'row',
				justifyContent: 'flex-end',
				alignItems: 'center',
				marginTop: 8,
			},
			actionsRow: {
				flexDirection: 'row',
				justifyContent: 'flex-end',
				alignItems: 'center',
				paddingHorizontal: 8,
				marginBottom: 8,
			},
			renameButton: {
				flex: 1 / 4,
				paddingTop: 14,
			},
		}),
		actions: StyleSheet.create({
			button: {
				minWidth: 120,
			},
			leftButton: {
				marginRight: 8,
			},
		}),
		fileUpload: StyleSheet.create({
			fileContainer: {
				marginBottom: 8,
				paddingVertical: 8,
				paddingHorizontal: 16,
				borderColor: theme['border-basic-color-4'],
				borderStyle: 'dashed',
				borderWidth: 3,
				alignItems: 'center',
				justifyContent: 'center',
				width: '100%',
			},
			buttons: {
				flexDirection: 'row',
				justifyContent: 'flex-start',
			},
			selectButton: {
				marginRight: 8,
			},
		}),
	};
}
