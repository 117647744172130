export enum CustomerInformationComponentLocators {
	customerIdField = 'customerIdField',
	customerFortressIdentityIdField = 'customerFortressIdentityIdField',
	customerFirstNameField = 'customerFirstNameField',
	customerLastNameField = 'customerLastNameField',
	customerMiddleNameField = 'customerMiddleNameField',
	customerEmailField = 'customerEmailField',
	customerDateOfBirthField = 'customerDateOfBirthField',
	customerSocialSecurityNumberField = 'customerSocialSecurityNumberField',
	customerStreetField = 'customerStreetField',
	customerStreet2Field = 'customerStreet2Field',
	customerCityField = 'customerCityField',
	customerStateField = 'customerStateField',
	customerZipCodeField = 'customerZipCodeField',
	customerPhoneField = 'customerPhoneField',
	customerMobilePhoneField = 'customerMobilePhoneField',
	customerPriorNameField = 'customerPriorNameField',
	customerPlaceOfBirthCityField = 'customerPlaceOfBirthCityField',
	customerPlaceOfBirthStateField = 'customerPlaceOfBirthStateField',
	customerPlaceOfBirthCountryField = 'customerPlaceOfBirthCountryField',
	customerEmployerName = 'customerEmployerName',
	customerEmployerAddress = 'customerEmployerAddress',
	customerEmployerCity = 'customerEmployerCity',
	customerEmployerState = 'customerEmployerState',
	customerEmployerZip = 'customerEmployerZip',
	employmentStatusField = 'employmentStatusField',
	customerSourceOfFundsField = 'customerSourceOfFundsField',
	customerAccountPurpose = 'customerAccountPurpose',
	customerFreezeButton = 'customerFreezeButton',
	customerEditButton = 'customerEditButton',
	customerSaveButton = 'customerSaveButton',
	customerCancelButton = 'customerCancelButton',
	customerFreezeCancelButton = 'customerFreezeCancelButton',
	customerFreezeSaveButton = 'customerFreezeSaveButton',
	customerJobTitle = 'customerJobTitle',
	customerClaimCancelButton = 'customerClaimCancelButton',
	customerClaimButton = 'customerClaimButton',

}
