import { gql, useQuery } from '@apollo/client';
import {
	EditAccountScreenGetAccountDetails,
	EditAccountScreenGetAccountDetailsVariables,
} from '@app/codegen';

export const EDIT_ACCOUNT_SCREEN_GET_ACCOUNT_DETAILS = gql`
	query EditAccountScreenGetAccountDetails($where: AccountFilterInput) {
		accounts(where: $where, first: 1) {
			nodes {
				id
				userId
				displayName
				accountNumber
				type
				status
				stage
				status
				custodian
				wallets {
					availableBalance
				}
				custodianAccount {
					id
					custodianAccountId
					custodianAccountType
				}
				sourceOfFunds
				accountPurpose
				user {
					id
					fortressIdentityId
					firstName
					lastName
					fullName
					middleName
					dateOfBirth
					socialSecurityNumber
					referralCode
					jobTitle
					kycStatus
					addresses {
						address
						address2
						city
						state
						zipCode
						country
						type
					}
					status
					email
					phone
					enableNotifications
					accounts {
						id
						type
						status
					}
					priorNames
					employerName
					employmentStatus
					marketingAttribute {
						referralId
					}
					isVip
				}
			}
		}
	}
`;

export function useEditAccountScreenAccountDetailsQuery(accountId: number) {
	return useQuery<
		EditAccountScreenGetAccountDetails,
		EditAccountScreenGetAccountDetailsVariables
	>(EDIT_ACCOUNT_SCREEN_GET_ACCOUNT_DETAILS, {
		variables: {
			where: {
				id: {
					eq: accountId,
				},
			},
		},
		skip: !accountId,
	});
}
