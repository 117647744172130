import { gql, useMutation, useQuery } from '@apollo/client';
import {
	AccountStage,
	EcsGetUserDetails_users_nodes as UserDetails,
	EditAccountScreenGetAccountDetails_accounts_nodes as AccountDetails,
	EditFundingScreen_CompleteFundingV2,
	EditFundingScreen_CompleteFundingV2Variables,
	EditFundingScreen_GetFunding,
	EditFundingScreen_GetFunding_fundings_nodes_fromAccount as FromAccount,
	EditFundingScreen_GetFunding_fundings_nodes_toAccount as ToAccount,
	EditFundingScreen_GetFundingVariables,
	FundingDirection,
	FundingStage,
	FundingType,
} from '@app/codegen';
import { useMaestroUserAtom } from '@app/core/atoms';
import { EditFundingScreenParams } from '@app/navigations';
import { useCustomersNavigation } from '@app/navigations/Main/Customers';
import { useFundingsInflowNavigation } from '@app/navigations/Main/FundingsInflow';
import { useFundingsOutflowNavigation } from '@app/navigations/Main/FundingsOutflow';
import {
	Documents,
	Emails,
	NavConfigOptions,
	Notes,
	StickyNav,
	TopHeader,
} from '@app/shared/components';
import { FundingAuditHistoryCardOrganism } from '@app/shared/components/organisms/FundingAuditHistoryCard/FundingAuditHistoryCardOrganism';
import { findSectionHelper } from '@app/shared/helpers';
import {
	AppAlert,
	AppScreen,
	ScreenSizeEnum,
	unMaskCurrency,
	useAppDevice,
	useAppState,
	useAppTheme,
	useNavigationParams,
} from '@itrustcapital/ui';
import * as ReactNavigation from '@react-navigation/native';
import { Text } from '@ui-kitten/components';
import React, { useEffect } from 'react';
import { ScrollView, StyleSheet, TouchableOpacity, View } from 'react-native';

import { FundingDetails, FundingDetailsDistribution } from './FundingDetails';
import { FundingWorkflow } from './FundingWorkflow';
import { FundingAssets } from './FundingsAssets';

export type EditFundingAccountDetails = FromAccount | ToAccount;

export enum EditFundingSectionLabel {
	FundingDetails = 'FUNDING_DETAILS',
	ListofAssets = 'LIST_OF_ASSETS',
	Emails = 'EMAILS',
	Documents = 'DOCUMENTS',
	Notes = 'NOTES',
	FundingAuditHistory = 'FUNDING_HISTORY',
}

export interface EditFundingScreenNavOptions extends NavConfigOptions<EditFundingSectionLabel> {}

export interface CustomerInfo {
	fullName?: string;
	id?: number;
}

export type EditFundingScreenNavRefs = {
	[K in EditFundingSectionLabel]: { x: number; y: number };
};

export const EDIT_FUNDING_SCREEN_GET_FUNDING = gql`
	query EditFundingScreen_GetFunding($fundingId: Int!) {
		fundings(where: { id: { eq: $fundingId } }, first: 1) {
			nodes {
				id
				direction
				type
				rolloverType
				status
				contributionYear
				contributionType
				transactionCode
				distributionCode
				stateWithholdings
				federalWithholdings
				createdAt
				rolloverFrom
				distributedToClient
				microTestAmount
				microTestTransactionId
				microTestAmountConfirmed
				federalWithholdingsTransactionId
				stateWithholdingsTransactionId
				distributedTotalAmount
				documents {
					id
				}
				stage
				custodian
				fundingAssets {
					id
					currencyType
					paymentType
					requestedAmount
					amount
					status
				}
				fromAccount {
					id
					displayName
					type
					stage
					status
					accountNumber
					custodian
					user {
						id
						firstName
						lastName
						fullName
						email
						phone
					}
				}
				fromExternalAccount {
					id
					institutionName
					accountNumber
					accountType
					user {
						id
						firstName
						lastName
						fullName
						email
						phone
					}
				}
				toAccount {
					id
					type
					stage
					status
					accountNumber
					custodian
					user {
						id
						firstName
						lastName
						fullName
						email
						phone
					}
				}
				toExternalAccount {
					id
					institutionName
					accountNumber
					accountType
					user {
						id
						firstName
						lastName
						fullName
						email
						phone
					}
				}
				transactionId
				paymentId
				paymentType
				associatedFundingId
			}
		}
	}
`;

export const EDIT_FUNDING_SCREEN_COMPLETE_FUNDING = gql`
	mutation EditFundingScreen_CompleteFundingV2($input: CompleteFundingV2Input!) {
		completeFundingV2(input: $input) {
			success
			errorMessage
		}
	}
`;

export function EditFundingScreen() {
	const styles = useCustomStyles();
	const theme = useAppTheme();
	const navigation = ReactNavigation.useNavigation();
	const navigationParams = useNavigationParams<EditFundingScreenParams>('Edit');
	const fundingInflowNavigation = useFundingsInflowNavigation();
	const fundingOutflowNavigation = useFundingsOutflowNavigation();
	const customersNavigation = useCustomersNavigation();
	const selectedSection = useAppState(EditFundingSectionLabel.FundingDetails);
	const fundingId = Number(navigationParams?.fundingId);
	const isEditingParent = useAppState(false);
	const maestroUserAtom = useMaestroUserAtom();

	const approveSuccessVisible = useAppState(false);
	const approveFailedVisible = useAppState(false);
	const errorAlertVisible = useAppState(false);

	const getFundingDetailsQuery = useQuery<
		EditFundingScreen_GetFunding,
		EditFundingScreen_GetFundingVariables
	>(EDIT_FUNDING_SCREEN_GET_FUNDING, {
		fetchPolicy: 'no-cache',
		variables: { fundingId },
		skip: !fundingId,
	});

	const [funding] = getFundingDetailsQuery.data?.fundings?.nodes || [];

	const [completeFunding, completeFundingMutation] = useMutation<
		EditFundingScreen_CompleteFundingV2,
		EditFundingScreen_CompleteFundingV2Variables
	>(EDIT_FUNDING_SCREEN_COMPLETE_FUNDING, {
		refetchQueries: [EDIT_FUNDING_SCREEN_GET_FUNDING],
		fetchPolicy: 'network-only',
	});

	useEffect(() => {
		if (
			completeFundingMutation?.data?.completeFundingV2?.success &&
			!approveSuccessVisible.get
		) {
			approveSuccessVisible.set(true);
		} else if (
			(completeFundingMutation?.data?.completeFundingV2?.success === false ||
				completeFundingMutation?.error) &&
			!errorAlertVisible.get
		) {
			errorAlertVisible.set(true);
		}
	}, [completeFundingMutation?.error, completeFundingMutation?.data?.completeFundingV2?.success]);

	const isDistribution = funding?.type === FundingType.DISTRIBUTION;
	const showDistributionForm = useAppState(isDistribution);

	useEffect(() => {
		if (funding) {
			if (isDistribution) {
				showDistributionForm.set(true);
				isEditingParent.set(false);
			} else {
				showDistributionForm.set(false);
				isEditingParent.set(false);
			}
		}
	}, [funding, isDistribution]);

	ReactNavigation.useFocusEffect(
		React.useCallback(() => {
			initScrollToCard();

			return () => selectedSection.set(EditFundingSectionLabel.FundingDetails);
		}, [navigationParams?.fundingStage])
	);

	const fundingAccount: EditFundingAccountDetails | null | undefined =
		funding?.direction === FundingDirection.INBOUND ? funding?.toAccount : funding?.fromAccount;
	const disabledWorkflow: boolean =
		(funding?.direction === FundingDirection.INBOUND &&
			funding.toAccount?.stage !== AccountStage.VERIFIED) ||
		(funding?.direction === FundingDirection.OUTBOUND &&
			funding.fromAccount?.stage !== AccountStage.VERIFIED);

	const NavOptions: { [K in EditFundingSectionLabel]: EditFundingScreenNavOptions } = {
		[EditFundingSectionLabel.FundingDetails]: {
			ref: React.useRef<HTMLElement & View>(null),
			label: EditFundingSectionLabel.FundingDetails,
			iconLib: 'fe',
			iconName: 'search',
		},
		[EditFundingSectionLabel.ListofAssets]: {
			ref: React.useRef<HTMLElement & View>(null),
			label: EditFundingSectionLabel.ListofAssets,
			iconLib: 'fa5',
			iconName: 'folder-open',
		},
		[EditFundingSectionLabel.Emails]: {
			ref: React.useRef<HTMLElement & View>(null),
			label: EditFundingSectionLabel.Emails,
			iconLib: 'ion',
			iconName: 'ios-cash-outline',
		},
		[EditFundingSectionLabel.Documents]: {
			ref: React.useRef<HTMLElement & View>(null),
			label: EditFundingSectionLabel.Documents,
			iconLib: 'fa5',
			iconName: 'folder-open',
			iconSize: 18,
		},
		[EditFundingSectionLabel.Notes]: {
			ref: React.useRef<HTMLElement & View>(null),
			label: EditFundingSectionLabel.Notes,
			iconLib: 'mat',
			iconName: 'people-outline',
		},
		[EditFundingSectionLabel.FundingAuditHistory]: {
			ref: React.useRef<HTMLElement & View>(null),
			label: EditFundingSectionLabel.FundingAuditHistory,
			iconLib: 'fe',
			iconName: 'user-check',
		},
	};

	const handleScroll = React.useCallback(
		findSectionHelper<EditFundingScreenNavOptions>(Object.values(NavOptions), (section) =>
			selectedSection.set(section.label)
		),
		[]
	);

	function initScrollToCard() {
		if (!navigationParams?.fundingStage) {
			return;
		}

		let label = EditFundingSectionLabel.FundingDetails;

		switch (navigationParams?.fundingStage) {
			case FundingStage.DOCUMENTS_PENDING:
			case FundingStage.ASSET_TRANSFER_PENDING:
			case FundingStage.PENDING_REVIEW:
			case FundingStage.WAITING_TO_PROCESS:
				label = EditFundingSectionLabel.FundingDetails;
				break;
			case FundingStage.DOCUMENTS_SENT_TO_CLIENT:
			case FundingStage.DOCUMENTS_SIGNED:
			case FundingStage.DOCUMENTS_SENT_TO_CUSTODIAN:
			case FundingStage.DOCUMENT_REVIEW:
				label = EditFundingSectionLabel.Emails;
				break;
		}

		selectedSection.set(label);
		setTimeout(() => {
			// @ts-ignore
			NavOptions[label].ref?.current?.scrollIntoView({
				behavior: 'smooth',
			});
		}, 100); //  timeout makes the page feel a lot smoother, allows for some data to populate
	}

	function getCustomerInfo(): CustomerInfo {
		switch (funding?.direction) {
			case FundingDirection.INBOUND:
				return {
					fullName: funding.toAccount?.user?.fullName!,
					id: funding.toAccount?.user?.id!,
				};
			case FundingDirection.OUTBOUND:
				return {
					fullName: funding.fromAccount?.user?.fullName!,
					id: funding.fromAccount?.user?.id!,
				};
			default:
				return {};
		}
	}

	function handleGoBack() {
		if (navigation.canGoBack()) {
			navigation.goBack();

			return;
		}

		if (navigationParams?.direction === FundingDirection.INBOUND) {
			fundingInflowNavigation.Home();
		} else {
			fundingOutflowNavigation.Home();
		}
	}

	function onApprovalRequest(transactionIdInput: string, totalAmountInput: string) {
		const isTransactionIdMatching = transactionIdInput.toString() === funding?.transactionId;

		const totalAmount = unMaskCurrency(totalAmountInput);
		const isTotalAmountMatching = isDistribution
			? totalAmount === funding.distributedTotalAmount
			: totalAmount === funding?.fundingAssets?.[0]?.amount;

		if (isTransactionIdMatching && isTotalAmountMatching) {
			completeFunding({
				variables: {
					input: {
						id: funding.id,
					},
				},
			});
		} else {
			approveFailedVisible.set(true);
		}
	}

	return (
		<AppScreen noMarginBottom noMaxWidth noPadding noScroll style={styles.main.container}>
			<AppAlert
				actions={[
					{
						title: 'Ok',
						status: 'danger',
						onPress: () => {
							errorAlertVisible.set(false);
						},
					},
				]}
				visible={errorAlertVisible.get}
				title={'Ooops'}
				message={'Something went wrong'}
			/>

			<AppAlert
				actions={[
					{
						title: 'Ok',
						status: 'danger',
						onPress: () => {
							approveFailedVisible.set(false);
						},
					},
				]}
				visible={approveFailedVisible.get}
				title={'Warning!'}
				message={
					'Information does not match for this funding. Please verify your inputs and try again'
				}
			/>

			<AppAlert
				actions={[
					{
						title: 'Confirm',
						status: 'success',
						onPress: () => {
							approveSuccessVisible.set(false);
						},
					},
				]}
				visible={approveSuccessVisible.get}
				title={'Funding Approved'}
				message={
					'The asset transfer is complete and funds are now available to the client.'
				}
			/>

			<TopHeader
				goBack={handleGoBack}
				subTitleColor={theme['text-warning-color']}
				title="Create / Edit Funding"
			>
				<View style={styles.header.row}>
					<TouchableOpacity
						onPress={() =>
							customersNavigation.Edit({
								customerId: getCustomerInfo().id!,
							})
						}
					>
						<Text
							appearance="alternative"
							category="h6"
							status="primary"
							style={styles.header.accountName}
						>
							{getCustomerInfo().fullName}
						</Text>
					</TouchableOpacity>
				</View>
			</TopHeader>
			<View style={styles.main.subContainer}>
				<StickyNav data={Object.values(NavOptions)} selectedSection={selectedSection.get} />
				<View style={styles.main.content}>
					<ScrollView scrollEventThrottle={16} onScroll={handleScroll}>
						<View style={styles.main.card}>
							<FundingWorkflow
								account={
									funding?.direction === FundingDirection.INBOUND
										? funding?.toAccount
										: funding?.fromAccount
								}
								disabled={disabledWorkflow}
								fundingId={fundingId}
								loading={
									getFundingDetailsQuery.loading ||
									completeFundingMutation?.loading
								}
								stage={funding?.stage || FundingStage.NONE}
								onApprovalRequest={onApprovalRequest}
							/>
						</View>

						{/* Funding Details */}
						<View ref={NavOptions.FUNDING_DETAILS.ref} style={styles.main.card}>
							{showDistributionForm.get ? (
								<FundingDetailsDistribution
									fundingDetails={funding}
									fundingId={fundingId}
									loading={getFundingDetailsQuery.loading}
								/>
							) : (
								<FundingDetails
									fundingDetails={funding}
									fundingId={fundingId}
									loading={getFundingDetailsQuery.loading}
								/>
							)}
						</View>

						{/* Funding Assets */}
						<View ref={NavOptions.LIST_OF_ASSETS.ref} style={styles.main.card}>
							<FundingAssets fundingId={fundingId} />
						</View>

						{/* Emails */}
						<View ref={NavOptions.EMAILS.ref} style={styles.main.card}>
							{fundingAccount && (
								<Emails
									accountDetails={fundingAccount as AccountDetails}
									userDetails={fundingAccount?.user as UserDetails}
									fundingId={fundingId}
								/>
							)}
						</View>

						{/* Documents */}
						<View ref={NavOptions.DOCUMENTS.ref} style={styles.main.card}>
							{fundingAccount && (
								<Documents
									accountDetails={fundingAccount as AccountDetails}
									fundingDetails={funding!}
									userId={fundingAccount.user?.id!}
								/>
							)}
						</View>

						{/* Notes */}
						<View ref={NavOptions.NOTES.ref} style={styles.main.card}>
							<Notes fundingId={fundingId} />
						</View>

						{maestroUserAtom?.isCrmUser && (
							<View ref={NavOptions.FUNDING_HISTORY.ref} style={styles.main.card}>
								<FundingAuditHistoryCardOrganism fundingId={fundingId} />
							</View>
						)}
					</ScrollView>
				</View>
			</View>
		</AppScreen>
	);
}

function useCustomStyles() {
	const appDevice = useAppDevice();

	const isScreenMd = appDevice.width >= ScreenSizeEnum.md;

	return {
		main: StyleSheet.create({
			container: {
				flexDirection: 'column',
			},
			subContainer: {
				flexDirection: isScreenMd ? 'row' : 'column',
				margin: 32,
				flex: 1,
			},
			content: {
				flex: 1,
				margin: 32,
			},
			card: {
				marginBottom: 8,
			},
		}),
		header: StyleSheet.create({
			row: {
				flexDirection: 'row',
				justifyContent: 'flex-end',
				alignItems: 'center',
				marginRight: 40,
				flex: 1,
			},
			accountName: {
				marginRight: 32,
				fontWeight: '400',
			},
		}),
	};
}
