import { gql, useMutation, useQuery } from '@apollo/client';
import {
	EditCustomerScreen_AddAstraUserIntentStatus,
	EditCustomerScreen_AddAstraUserIntentStatusVariables,
	EditCustomerScreen_GetAstraUserIntentStatus,
	EditCustomerScreen_GetAstraUserIntentStatusVariables,
} from '@app/codegen';
import { CustomCard } from '@app/shared/components';
import { AppAlert, humanize, useAppState } from '@itrustcapital/ui';
import { Button, Spinner, Text } from '@ui-kitten/components';
import React, { useEffect } from 'react';
import { StyleSheet, View } from 'react-native';

export interface AstraProps {
	userId: number;
}

export const EDIT_CUSTOMER_SCREEN_ADD_ASTRA_USER_INTENT_STATUS = gql`
	mutation EditCustomerScreen_AddAstraUserIntentStatus($userId: Int!) {
		addAstraUserIntent(input: { userId: $userId }) {
			success
			errorMessage
			data {
				status
			}
		}
	}
`;

export const EDIT_CUSTOMER_SCREEN_GET_ASTRA_USER_INTENT_STATUS = gql`
	query EditCustomerScreen_GetAstraUserIntentStatus($userId: Int!) {
		users(where: { id: { eq: $userId } }, first: 1) {
			nodes {
				astraUserIntentStatus
			}
		}
	}
`;

export function Astra(props: AstraProps) {
	const styles = useCustomStyles();

	const errorVisible = useAppState(false);

	const getAstraUserIntentStatusQuery = useQuery<
		EditCustomerScreen_GetAstraUserIntentStatus,
		EditCustomerScreen_GetAstraUserIntentStatusVariables
	>(EDIT_CUSTOMER_SCREEN_GET_ASTRA_USER_INTENT_STATUS, {
		variables: {
			userId: props?.userId,
		},
		skip: !props.userId,
	});

	const [rerunAstraUserIntent, rerunAstraUserIntentMutation] = useMutation<
		EditCustomerScreen_AddAstraUserIntentStatus,
		EditCustomerScreen_AddAstraUserIntentStatusVariables
	>(EDIT_CUSTOMER_SCREEN_ADD_ASTRA_USER_INTENT_STATUS, {
		variables: {
			userId: props.userId,
		},
	});

	const astraStatus =
		getAstraUserIntentStatusQuery?.data?.users?.nodes?.[0]?.astraUserIntentStatus ||
		rerunAstraUserIntentMutation?.data?.addAstraUserIntent?.data?.status;

	useEffect(() => {
		if (rerunAstraUserIntentMutation?.data?.addAstraUserIntent?.errorMessage) {
			errorVisible.set(true);
		}
	}, [rerunAstraUserIntentMutation?.data?.addAstraUserIntent?.errorMessage]);

	return (
		<>
			<CustomCard
				header={() => (
					<View style={styles.main.header}>
						<Text category="h6">Astra</Text>
					</View>
				)}
			>
				<AppAlert
					actions={[
						{
							title: 'OK',
							status: 'danger',
							onPress: () => errorVisible.set(false),
						},
					]}
					message="Sorry, something went wrong. Please try again later."
					title="Request Failed"
					visible={errorVisible.get}
				/>

				<View
					style={{
						flexDirection: 'row',
						justifyContent: 'space-between',
					}}
				>
					<View>
						<Text style={styles.main.statusHeader}>status:</Text>
						<Text style={styles.main.status}>
							{
								// eslint-disable-next-line no-nested-ternary
								rerunAstraUserIntentMutation.loading ? (
									<Spinner size="tiny" status="basic" />
								) : astraStatus ? (
									humanize(astraStatus)
								) : (
									'N/A'
								)
							}
						</Text>
					</View>

					<Button
						disabled={
							rerunAstraUserIntentMutation.loading || astraStatus === 'approved'
						}
						onPress={() => rerunAstraUserIntent()}
					>
						{!rerunAstraUserIntentMutation.loading ? (
							'Rerun Astra'
						) : (
							<>
								<Spinner size="tiny" status="basic" />
							</>
						)}
					</Button>
				</View>
			</CustomCard>
		</>
	);
}

function useCustomStyles() {
	return {
		main: StyleSheet.create({
			header: {
				flexDirection: 'row',
				justifyContent: 'space-between',
				alignItems: 'center',
			},
			statusHeader: {
				fontVariant: ['small-caps'],
			},
			status: {
				fontSize: 16,
				fontWeight: '800',
				textTransform: 'uppercase',
			},
		}),
		tags: StyleSheet.create({
			container: {
				flex: 1,
				flexDirection: 'row',
				flexWrap: 'wrap',
			},
			group: {
				flexDirection: 'row',
			},
			seperator: {
				paddingHorizontal: 8,
			},
		}),
	};
}
