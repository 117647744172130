import { gql, useLazyQuery } from '@apollo/client';
import { CustomCard, CustomDataTable, ITrustColumn } from '@app/shared/components';
import { localDateTimeFormat } from '@itrustcapital/ui';
import { Button, Input } from '@ui-kitten/components';
import {
	AuditLogsCardOrganism_GetFundingHistories,
	AuditLogsCardOrganism_GetFundingHistories_fundingHistories_data as FundingAuditData,
	AuditLogsCardOrganism_GetFundingHistoriesVariables,
} from 'codegen/AuditLogsCardOrganism_GetFundingHistories';
import React from 'react';
import { StyleSheet, View } from 'react-native';

interface FundingAuditHistoryCardOrganismProps {
	fundingId?: number;
}

const columns: ITrustColumn<FundingAuditData>[] = [
	{
		key: 'funding',
		name: 'Modified By Role',
		maxWidth: 130,
		selector: (row) => row?.funding?.modifiedByRole ?? '',
	},
	{
		key: 'funding',
		name: 'Modified By User ID',
		maxWidth: 130,
		selector: (row) => row?.funding?.modifiedByUserId ?? '',
	},
	{
		key: 'validFrom',
		name: 'Valid From',
		minWidth: 200,
		sortable: false,
		selector: (row) => localDateTimeFormat(row.validFrom),
	},
	{
		key: 'validTo',
		name: 'Valid To',
		minWidth: 200,
		sortable: false,
		selector: (row) =>
			row?.validTo === '9999-12-31T23:59:59.999Z'
				? 'Current'
				: localDateTimeFormat(row.validTo),
	},
	{
		key: 'funding',
		name: 'Direction',
		maxWidth: 150,
		sortable: true,
		selector: (row) => row?.funding?.direction ?? '',
	},
	{
		key: 'funding',
		name: 'Type',
		minWidth: 200,
		sortable: false,
		selector: (row) => row?.funding?.type ?? '',
	},
	{
		key: 'funding',
		name: 'Status',
		maxWidth: 150,
		sortable: true,
		selector: (row) => row?.funding?.status ?? '',
	},
	{
		key: 'funding',
		name: 'Stage',
		maxWidth: 150,
		sortable: true,
		selector: (row) => row?.funding?.stage ?? '',
	},
];

export const AUDIT_LOGS_SCREEN_GET_FUNDING_HISTORIES = gql`
	query AuditLogsCardOrganism_GetFundingHistories($input: GetFundingHistoriesInput) {
		fundingHistories(input: $input) {
			success
			errorMessage
			data {
				funding {
					modifiedByRole
					modifiedByUserId
					direction
					type
					status
					stage
				}
				validFrom
				validTo
			}
		}
	}
`;

export const FundingAuditHistoryCardOrganism = (props: FundingAuditHistoryCardOrganismProps) => {
	const [fundingIdSearchText, setFundingIdSearchText] = React.useState('');

	const [getFundingAudit, getFundingAuditQuery] = useLazyQuery<
		AuditLogsCardOrganism_GetFundingHistories,
		AuditLogsCardOrganism_GetFundingHistoriesVariables
	>(AUDIT_LOGS_SCREEN_GET_FUNDING_HISTORIES);

	React.useEffect(() => {
		if (props?.fundingId) {
			getFundingAudit({
				variables: {
					input: {
						fundingId: props.fundingId,
					},
				},
			});
		}
	}, [props?.fundingId]);

	return (
		<CustomCard style={styles.main.container} header="Funding Audit History">
			{!props?.fundingId && (
				<View style={styles.main.searchContainer}>
					<Input
						value={fundingIdSearchText}
						placeholder="Enter Funding ID"
						onChangeText={(text) => {
							setFundingIdSearchText(text);
						}}
					/>

					<Button
						style={styles.main.searchBtn}
						size="small"
						status="primary"
						onPress={() => {
							if (+fundingIdSearchText) {
								getFundingAudit({
									variables: {
										input: {
											fundingId: +fundingIdSearchText,
										},
									},
								});
							} else {
								setFundingIdSearchText('');
							}
						}}
					>
						Search
					</Button>
				</View>
			)}

			<CustomDataTable
				inModal
				columns={columns}
				data={getFundingAuditQuery?.data?.fundingHistories?.data as FundingAuditData[]}
				progressPending={getFundingAuditQuery.loading}
				refetch={(variables) => {
					getFundingAuditQuery?.refetch?.({
						...variables,
					});
				}}
			/>
		</CustomCard>
	);
};

const styles = {
	main: StyleSheet.create({
		container: {
			minWidth: 600,
			marginTop: 16,
		},
		searchContainer: {
			flexDirection: 'row',
			marginVertical: 8,
		},
		searchBtn: {
			marginLeft: 8,
		},
	}),
};
