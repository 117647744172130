import { DiscoveryDocument } from 'expo-auth-session';
import { atom, SetterOrUpdater } from 'recoil';

import AsyncStorage from '@react-native-async-storage/async-storage';

export interface AuthState {
	isAuthenticated: boolean;
	discovery: DiscoveryDocument | null;
	sub: string | null;
	userId: string | null;
	onRefreshTokenExpired: null | (() => void);
}

export const authAtom = atom<AuthState>({
	key: 'authAtom',
	default: {
		isAuthenticated: false,
		discovery: null,
		sub: null,
		userId: null,
		onRefreshTokenExpired: null,
	},
	dangerouslyAllowMutability: false,
});

export function AuthAtomObserver(authState: AuthState) {
	AsyncStorage.setItem('auth', JSON.stringify(authState));
}
export async function AuthAtomInitializer(set: SetterOrUpdater<AuthState>) {
	const auth = await AsyncStorage.getItem('auth');
	if (auth) {
		set(JSON.parse(auth));
	}
}
