import { gql, NetworkStatus, OperationVariables, useQuery } from '@apollo/client';
import {
	SharedAtom_GetCrmUserDetails,
	SharedAtom_GetCrmUserDetails_crmUsers_nodes as CrmUser,
	SystemRole,
} from '@app/codegen';
import { authAtom, useAppStateRecoil } from '@itrustcapital/ui';
import React from 'react';
import { atom } from 'recoil';

export type MaestroUserState = {
	loading: boolean;
	networkStatus?: NetworkStatus;
	isCrmWriteUser?: boolean;
	isCrmUser?: boolean;
	isAdmin?: boolean;
	refetch: (variables?: Partial<OperationVariables> | undefined) => void;
	user?: CrmUser | null;
};

export const MaestroUserAtom = atom<MaestroUserState>({
	key: 'maestroUserAtom',
	default: {
		loading: false,
		isCrmWriteUser: false,
		isCrmUser: false,
		isAdmin: false,
		networkStatus: undefined,
		refetch: () => {},
	},
	dangerouslyAllowMutability: false,
});

export const SHARED_ATOM_GET_CRM_USER_DETAILS = gql`
	query SharedAtom_GetCrmUserDetails($userId: Int!) {
		crmUsers(where: { id: { eq: $userId } }, first: 1) {
			nodes {
				id
				firstName
				lastName
				fullName
				email
				status
				role
			}
		}
	}
`;

export const useMaestroUserAtom = (): MaestroUserState => {
	const userAtomState = useAppStateRecoil(MaestroUserAtom);

	const authState = useAppStateRecoil(authAtom);

	const maestroUserQuery = useQuery<SharedAtom_GetCrmUserDetails>(
		SHARED_ATOM_GET_CRM_USER_DETAILS,
		{
			variables: { userId: +authState.get?.userId! },
			skip: !authState.get?.userId,
		}
	);

	const [crmUser] = maestroUserQuery.data?.crmUsers?.nodes || [];

	React.useEffect(() => {
		userAtomState.set({
			...userAtomState.get,
			loading: maestroUserQuery.loading,
			isCrmWriteUser:
				crmUser?.role === SystemRole.SUPER_ADMIN ||
				crmUser?.role === SystemRole.ADMIN ||
				crmUser?.role === SystemRole.FUNDING,
			isCrmUser:
				crmUser?.role === SystemRole.SUPER_ADMIN ||
				crmUser?.role === SystemRole.ADMIN ||
				crmUser?.role === SystemRole.FUNDING ||
				crmUser?.role === SystemRole.PROCESSING ||
				crmUser?.role === SystemRole.PROCESSING_T1 ||
				crmUser?.role === SystemRole.GENERAL,
			isAdmin: crmUser?.role === SystemRole.ADMIN || crmUser?.role === SystemRole.SUPER_ADMIN,
			networkStatus: maestroUserQuery.networkStatus,
			user: crmUser,
			refetch: maestroUserQuery.refetch,
		});
	}, [
		maestroUserQuery.loading,
		maestroUserQuery.networkStatus,
		maestroUserQuery.data,
		maestroUserQuery.refetch,
	]);

	return userAtomState.get;
};
