import { gql, useMutation } from '@apollo/client';
import {
	EcsGetUserDetails_users_nodes,
	EditCustomerScreen_AddAstraUserIntentStatus,
	EditCustomerScreen_AddAstraUserIntentStatusVariables,
	SystemRole,
} from '@app/codegen';
import { useMaestroUserAtom } from '@app/core/atoms';
import { CustomCard } from '@app/shared/components';
import { AppAlert, AppIcon, useAppState } from '@itrustcapital/ui';
import { Button, Input, Spinner } from '@ui-kitten/components';
import React, { useMemo } from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native';

type UserInfo = EcsGetUserDetails_users_nodes;

export interface SecurityQuestionsProps {
	userInfo: UserInfo | null;
}

export const SECURITY_QUESTIONS_CARD_DELETE_SECURITY_ANSWERS = gql`
	mutation SecurityQuestionsCard_DeleteSecurityAnswers($userId: Int!) {
		deleteSecurityAnswers(input: { userId: $userId }) {
			success
			errorMessage
		}
	}
`;

export function SecurityQuestions(props: SecurityQuestionsProps) {
	const styles = useCustomStyles();
	const maestroUserAtom = useMaestroUserAtom();

	const errorVisible = useAppState(false);
	const deleteSecurityAnswersVisible = useAppState(false);
	const answerVisibilityStates = useAppState([false, false, false]);

	const [deleteSecurityAnswers, deleteSecurityAnswersMutation] = useMutation<
		EditCustomerScreen_AddAstraUserIntentStatus,
		EditCustomerScreen_AddAstraUserIntentStatusVariables
	>(SECURITY_QUESTIONS_CARD_DELETE_SECURITY_ANSWERS, {
		variables: {
			userId: props?.userInfo?.id!,
		},
		refetchQueries: ['EcsGetUserDetails'],
	});

	const QAs: { id: number; question: string; answer: string }[] = useMemo(() => {
		const results: { id: number; question: string; answer: string }[] = [];

		props?.userInfo?.securityAnswers?.forEach((qa, index) => {
			results.push({
				id: qa?.id || index,
				question: qa?.securityQuestion?.text || '',
				answer: qa?.text || '',
			});
		});

		if (!results.length) {
			return [
				{ id: 0, question: '', answer: '' },
				{ id: 1, question: '', answer: '' },
				{ id: 2, question: '', answer: '' },
			];
		}

		return results;
	}, [props?.userInfo?.securityAnswers]);

	const allQuestionsEmpty = QAs.every((qa) => qa.question.trim() === '');

	return (
		<>
			<CustomCard header="Security Questions">
				<AppAlert
					actions={[
						{
							title: 'OK',
							status: 'danger',
							onPress: () => errorVisible.set(false),
						},
					]}
					message="Sorry, something went wrong. Please try again later."
					title="Request Failed"
					visible={errorVisible.get}
				/>

				<AppAlert
					actions={[
						{
							title: 'Cancel',
							status: 'primary',
							appearance: 'outline',
							onPress: () => deleteSecurityAnswersVisible.set(false),
						},
						{
							title: 'Delete',
							status: 'danger',
							onPress: () => {
								deleteSecurityAnswersVisible.set(false);
								deleteSecurityAnswers();
							},
						},
					]}
					message="All security questions and answers will be delted"
					title="Are you sure?"
					visible={deleteSecurityAnswersVisible.get}
				/>

				<View style={styles.main.container}>
					<Button
						status="danger"
						disabled={
							allQuestionsEmpty ||
							deleteSecurityAnswersMutation.loading ||
							!(
								maestroUserAtom.user?.role === SystemRole.SUPER_ADMIN ||
								maestroUserAtom.user?.role === SystemRole.ADMIN
							)
						}
						onPress={() => deleteSecurityAnswersVisible.set(true)}
					>
						{!deleteSecurityAnswersMutation.loading ? (
							'Delete'
						) : (
							<>
								<Spinner size="tiny" status="basic" />
							</>
						)}
					</Button>
				</View>

				{QAs?.map((qa, qaIndex) => (
					<View key={qa.id} style={styles.main.questions}>
						<Input
							disabled
							value={qa.question}
							label={`Security Question ${qaIndex + 1}`}
							placeholder="N/A"
							textStyle={{ height: 26 }}
							style={styles.main.input}
						/>

						<Input
							disabled
							secureTextEntry={!answerVisibilityStates.get[qaIndex]}
							accessoryRight={() => (
								<TouchableOpacity
									onPress={() => {
										const update = answerVisibilityStates.get?.map(
											(visible, visibilityIndex) => {
												if (qaIndex === visibilityIndex) {
													return !visible;
												}

												return visible;
											}
										);

										answerVisibilityStates.set(update);
									}}
								>
									<AppIcon
										lib="ion"
										name={
											answerVisibilityStates.get[qaIndex]
												? 'eye-outline'
												: 'eye-off'
										}
										size={20}
									/>
								</TouchableOpacity>
							)}
							value={qa.answer}
							label={`Security Answer ${qaIndex + 1}`}
							placeholder="N/A"
							textStyle={{ height: 26 }}
							style={styles.main.answerInput}
						/>
					</View>
				))}
			</CustomCard>
		</>
	);
}

function useCustomStyles() {
	return {
		main: StyleSheet.create({
			container: {
				flexDirection: 'row',
				justifyContent: 'flex-end',
			},
			questions: {
				flexDirection: 'row',
				marginBottom: 12,
			},
			input: { flex: 1, marginRight: 10 },
			answerInput: { flex: 1 },
		}),
	};
}
