import {
	AccountsScreen_GetAccounts_accounts_nodes as Account,
	AccountStage,
	AccountStatus,
} from '@app/codegen';
import { AccountScreenLocators } from '@app/e2e/screens/Accounts';
import { useAccountsNavigation } from '@app/navigations/Main/Accounts';
import {
	CustomDataTable,
	DangerAssetSVG,
	ITrustAction,
	ITrustColumn,
} from '@app/shared/components';
import { accountTypeHumanizer } from '@app/shared/helpers';
import { AppAlert, humanize, localDateFormat } from '@itrustcapital/ui';
import React from 'react';
import Toast from 'react-native-toast-message';

import { AccountExtraStage } from '../AccountsScreenForms';
import { useAccountsScreenContext } from '../AccountsScreenProvider';

export function AccountsTable() {
	const accountStackNavigation = useAccountsNavigation();
	const context = useAccountsScreenContext();

	const columns: ITrustColumn<Account>[] = [
		{
			key: 'user',
			name: 'Customer Name',
			minWidth: 300,
			sortable: true,
			selector: (row) => row.user?.fullName,
			sortOverride: (sortDirection) => ({ user: { firstName: sortDirection } }),
		},
		{
			key: 'accountNumber',
			name: 'Account #',
			maxWidth: 130,
			sortable: true,
			selector: (row) => row.accountNumber,
		},
		{
			key: 'createdAt',
			name: 'Created Date',
			maxWidth: 130,
			sortable: true,
			selector: (row) => localDateFormat(row.createdAt),
		},
		{
			key: 'type',
			name: 'Account Type',
			maxWidth: 180,
			minWidth: 150,
			sortable: true,
			selector: (row) => accountTypeHumanizer(row.type),
		},
		{
			key: 'status',
			name: 'Status',
			maxWidth: 130,
			center: true,
			sortable: true,
			selector: (row) => humanize(row.status),
		},
	];

	const documentsColumns: ITrustColumn<Account>[] = [
		{
			key: 'user',
			name: 'Customer Name',
			minWidth: 300,
			sortable: true,
			selector: (row) => row.user?.fullName,
			sortOverride: (sortDirection) => ({ user: { firstName: sortDirection } }),
		},
		{
			key: 'accountNumber',
			name: 'Account #',
			sortable: true,
			selector: (row) => row.accountNumber,
		},
		{
			key: 'createdAt',
			name: 'Sent Date',
			sortable: true,
			selector: (row) => localDateFormat(row.documentSentAt),
		},
		{
			key: 'createdAt',
			name: 'Signed Date',
			sortable: true,
			selector: (row) => localDateFormat(row.documentSignedAt),
		},
	];

	const actions: ITrustAction<Account>[] = [
		{
			handler: (row) => {
				accountStackNavigation.Edit({
					accountId: row.id,
					accountStage: context.filterAccountsForm.getValues()
						.accountStage as AccountStage,
				});
			},
			iconLib: 'matc',
			iconName: 'pencil',
			name: 'edit',
		},
		{
			handler: (row) => {
				context.selectedRow.set(row);
				context.deleteVisible.set(true);
			},
			iconLib: 'ion',
			iconName: 'trash',
			name: 'trash',
			isDisabled: (row) => row.status === AccountStatus.CLOSED,
		},
	];

	async function onDeleteSubmit(): Promise<void> {
		try {
			await context.onDeleteAccount({
				variables: {
					input: {
						id: context.selectedRow.get?.id || 0,
						status: AccountStatus.CLOSED,
					},
				},
			});
			Toast.show({
				type: 'success',
				text2: 'Account has been deleted',
			});

			context.deleteVisible.set(false);
			context.accountsQuery.refetch();
		} catch (err) {
			Toast.show({
				type: 'error',
				text2: 'Account failed to delete',
			});
		}
	}

	function onCancelSubmit(): void {
		context.selectedRow.set(null);
		context.deleteVisible.set(false);
	}

	function onRefetchTable(variables: any): void {
		context.accountsQuery.refetch({
			...variables,
			where: context.tableState.where.get,
			input: context.tableInput.get,
		});
	}

	return (
		<>
			<AppAlert
				actions={[
					{
						testID: AccountScreenLocators.accountDeleteModalCancelButton,
						title: 'Cancel',
						status: 'primary',
						appearance: 'outline',
						onPress: onCancelSubmit,
					},
					{
						testID: AccountScreenLocators.accountDeleteModalConfirmButton,
						loading: context.deleteAccountLoading,
						title: 'Confirm',
						status: 'danger',
						onPress: onDeleteSubmit,
					},
				]}
				message={`Account ${
					context.selectedRow.get?.user?.fullName ||
					context.selectedRow.get?.accountNumber
				} will be deleted`}
				title="Are you sure?"
				visible={context.deleteVisible.get}
				xmlIcon={DangerAssetSVG}
			/>
			<CustomDataTable
				{...context.tableState.props}
				columns={
					context.stageType.get !== AccountExtraStage.ADDITIONAL_DOCUMENTS
						? columns
						: documentsColumns
				}
				data={context.accountsQuery.data?.accounts?.nodes as Account[]}
				progressPending={context.accountsQuery.loading}
				refetch={onRefetchTable}
				rowActions={actions}
			/>
		</>
	);
}
