import { gql, useMutation } from '@apollo/client';
import {
	DocumentStage,
	DocumentType,
	DocuSignTemplateType,
	EditFundingScreen_GetFunding_fundings_nodes as FundingDetails,
	FileTemplateType,
	OrganismsCdAddDocument,
	OrganismsCdAddDocumentVariables,
} from '@app/codegen';
import { CustomCard } from '@app/shared/components';
import { AppModal, humanize, useAppState } from '@itrustcapital/ui';
import { Button, Input, Spinner } from '@ui-kitten/components';
import React from 'react';
import { StyleSheet, View } from 'react-native';

export const ORGANISMS_CD_ADD_DOCUMENT = gql`
	mutation OrganismsCdAddDocument($input: AddDocumentInput!) {
		addDocument(input: $input) {
			success
			errorMessage
			data {
				id
			}
		}
	}
`;

export interface CreateDocumentProps {
	template: FileTemplateType | DocuSignTemplateType;
	accountId?: number;
	fundingDetails?: FundingDetails;
	userId: number;
	visible: boolean;
	toggleVisible: () => void;
	onSave: (success: boolean) => void;
}

export function CreateDocument(props: CreateDocumentProps) {
	const styles = useCustomStyles();
	const isTemplateDocusign = Object.values(DocuSignTemplateType).includes(
		props.template as DocuSignTemplateType
	);

	const documentName = useAppState('');

	const documentRequiresName = props.template === FileTemplateType.OTHER;

	const requiresWizardPdf =
		props?.template === FileTemplateType.REFUND_CONTRIBUTION ||
		props?.template === FileTemplateType.PREMIUM_CUSTODY_ACCOUNT_WITHDRAWAL;

	const [onAddDocument, addDocumentQuery] = useMutation<
		OrganismsCdAddDocument,
		OrganismsCdAddDocumentVariables
	>(ORGANISMS_CD_ADD_DOCUMENT);

	const type = React.useMemo(() => {
		if (requiresWizardPdf) {
			return DocumentType.WIZARD_PDF;
		}

		return isTemplateDocusign ? DocumentType.DOCU_SIGN : DocumentType.FILE;
	}, [props?.template]);

	async function onSubmit() {
		try {
			await onAddDocument({
				variables: {
					input: {
						fileTemplate: isTemplateDocusign
							? undefined
							: (props.template as FileTemplateType),
						docuSignTemplate: isTemplateDocusign
							? (props.template as DocuSignTemplateType)
							: undefined,
						type,
						// its associated with whichever screen it is on.
						userId: props.userId,
						accountId: props.accountId,
						fundingId: props.fundingDetails?.id,
						stage: DocumentStage.DRAFT,
						name: documentName.get,
					},
				},
			});
			props.toggleVisible();
			props.onSave(true);
		} catch (err) {
			props.onSave(false);
		}
	}

	return (
		<AppModal visible={props.visible}>
			<CustomCard
				hasCloseButton
				footer={() => (
					<View style={styles.footer.container}>
						<Button
							accessoryLeft={() =>
								addDocumentQuery.loading ? (
									<View>
										<Spinner size="tiny" status="basic" />
									</View>
								) : (
									<></>
								)
							}
							disabled={documentRequiresName && !documentName.get}
							style={styles.footer.button}
							onPress={addDocumentQuery.loading ? undefined : onSubmit}
						>
							{!addDocumentQuery.loading && isTemplateDocusign
								? 'Generate Docusign'
								: 'Generate File'}
						</Button>
					</View>
				)}
				handleClosePress={props.toggleVisible}
				header="Create Document"
			>
				<View style={styles.content.inputRow}>
					<Input
						disabled
						label="Document template"
						style={styles.content.control}
						value={humanize(props.template)}
					/>
				</View>

				<View style={styles.content.inputRow}>
					<Input
						disabled
						label="Record"
						style={styles.content.thirdControl}
						value={`Account-${props.accountId}`}
					/>
					<Input
						disabled
						label="Type"
						style={styles.content.thirdControl}
						value={humanize(type)}
					/>
					<Input
						disabled
						label="Requires Signature"
						style={styles.content.thirdControl}
						value={isTemplateDocusign || requiresWizardPdf ? 'Yes' : 'No'}
					/>
				</View>

				{props?.template === FileTemplateType.REFUND_CONTRIBUTION && (
					<View style={styles.content.inputRow}>
						<Input
							disabled
							label="Currency Type"
							style={styles.content.thirdControl}
							value={`USD`}
						/>
						<Input
							disabled
							label="Corrected Funding ID"
							style={styles.content.thirdControl}
							value={props?.fundingDetails?.associatedFundingId?.toString()}
						/>
						<Input
							disabled
							label="Total Amount"
							style={styles.content.thirdControl}
							value={props?.fundingDetails?.fundingAssets?.[0]?.requestedAmount}
						/>
					</View>
				)}

				{documentRequiresName && (
					<View style={styles.content.inputRow}>
						<Input
							label="Document Name *"
							style={styles.content.control}
							value={documentName.get}
							onChangeText={(text) => documentName.set(text)}
						/>
					</View>
				)}
			</CustomCard>
		</AppModal>
	);
}

function useCustomStyles() {
	return {
		header: StyleSheet.create({
			container: {
				flexDirection: 'row',
				justifyContent: 'space-between',
				alignItems: 'center',
			},
		}),
		content: StyleSheet.create({
			control: {
				flex: 1,
			},
			thirdControl: {
				flex: 0.33,
			},
			inputRow: {
				flexDirection: 'row',
				justifyContent: 'space-between',
				alignItems: 'center',
				marginBottom: 8,
			},
			actions: {
				flexDirection: 'row',
				justifyContent: 'flex-end',
			},
		}),
		footer: StyleSheet.create({
			container: {
				flexDirection: 'row',
				justifyContent: 'flex-end',
				alignItems: 'center',
			},
			leftButton: {
				marginRight: 8,
			},
			button: {
				minWidth: 120,
			},
		}),
	};
}
