import { gql, useLazyQuery, useMutation } from '@apollo/client';
import {
	EcsGetUserDetails_users_nodes as UserDetails,
	EcsGetUserDetails_users_nodes_accounts as UserAccountDetails,
	EditAccountScreenGetAccountDetails_accounts_nodes as AccountDetails,
	EditAccountScreenGetAccountDetails_accounts_nodes_user as AccountUserDetails,
	EmailAddressType,
	EmailTemplateType,
	OrganismsNemGetEmailDetails,
	OrganismsNemGetEmailDetailsVariables,
	OrganismsNemGetTemplate,
	OrganismsNemGetTemplateVariables,
	OrganismsNemSendEmail,
	OrganismsNemSendEmailVariables,
} from '@app/codegen';
import { EmailModalComponentLocators } from '@app/e2e/shared/Emails';
import { CustomCard, RichTextEditor } from '@app/shared/components';
import { emailTemplateTypeHumanizer } from '@app/shared/helpers';
import { yupResolver } from '@hookform/resolvers/yup';
import { AppModal, AppSelector, useAppDevice } from '@itrustcapital/ui';
import { Button, Input, Spinner, Text } from '@ui-kitten/components';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { StyleSheet, View } from 'react-native';
import * as yup from 'yup';

export type EmailModalMode = 'New' | 'View' | null;

export interface EmailModalProps {
	visible: boolean;
	accountDetails?: UserAccountDetails | AccountDetails;
	userDetails: UserDetails | AccountUserDetails;
	id?: number;
	fundingId?: number;
	mode?: EmailModalMode;
	toggleVisible: () => void;
	onSaved: (success: boolean) => void;
}

// email templates that do NOT require any account info
const emailTemplatesGenericInfo = [
	EmailTemplateType.ASSET_TRANSFER_PENDING,
	EmailTemplateType.GOOGLE_REVIEWS,
	EmailTemplateType.ITC_TRANSFER_RECEIPT,
	EmailTemplateType.REVIEW_REQUEST,
	EmailTemplateType.REVIEW_THANK_YOU,
	EmailTemplateType.TRANSFER_FORM_FOLLOW_UP,
	EmailTemplateType.TRANSFER_FORM_FURTHER_STEPS,
];

// email templates that DO required account info
const emailTemplatesRequiringAccountInfo = [
	EmailTemplateType.FORTRESS_FUNDING_CONTRIBUTION,
	EmailTemplateType.FORTRESS_FUNDING_ROLLOVER,
	EmailTemplateType.FUNDING_CONTRIBUTION,
	EmailTemplateType.FUNDING_ROLLOVER,
];

const fromEmails = [
	'processing@itrustcapital.com',
	'transfers@itrustcapital.com',
	'fundings@itrustcapital.com',
	'inkinds@itrustcapital.com',
];

const schema = yup
	.object({
		from: yup.string().email().required().default(''),
		to: yup.string().email().required().default(''),
		template: yup
			.mixed<EmailTemplateType>()
			.oneOf(Object.values(EmailTemplateType))
			.default(EmailTemplateType.NONE),
		subject: yup.string().required().default(''),
		body: yup.string().default(''),
		newBody: yup.string().required().default(''),
	})
	.defined();

export type NewEmailMessageFormData = yup.TypeOf<typeof schema>;

export const ORGANISMS_NEM_GET_EMAIL_DETAILS = gql`
	query OrganismsNemGetEmailDetails($id: Int) {
		emails(where: { id: { eq: $id } }, first: 1) {
			nodes {
				id
				subject
				body
				template
				templateId
				from
				to
				cc
				bcc
				sentByUserId
				sentByRole
				userId
				accountId
				fundingId
			}
		}
	}
`;

export const ORGANISMS_NEM_SEND_EMAIL = gql`
	mutation OrganismsNemSendEmail($input: SendEmailInput!) {
		sendEmail(input: $input) {
			success
			errorMessage
		}
	}
`;

export const ORGANISMS_NEM_GET_TEMPLATE = gql`
	query OrganismsNemGetTemplate($input: GetEmailTemplateInput!) {
		emailTemplate(input: $input) {
			errorMessage
			success
			data {
				subject
				body
				templateType
			}
		}
	}
`;

export function EmailModal(props: EmailModalProps) {
	const styles = useCustomStyles();

	const form = useForm<NewEmailMessageFormData>({
		mode: 'all',
		criteriaMode: 'all',
		resolver: yupResolver(schema),
		defaultValues: schema.cast({}),
	});
	const subjectWatch = form.watch('subject');

	const [getEmail] = useLazyQuery<
		OrganismsNemGetEmailDetails,
		OrganismsNemGetEmailDetailsVariables
	>(ORGANISMS_NEM_GET_EMAIL_DETAILS, {
		onCompleted,
	});

	const [getTemplate, getTemplateQuery] = useLazyQuery<
		OrganismsNemGetTemplate,
		OrganismsNemGetTemplateVariables
	>(ORGANISMS_NEM_GET_TEMPLATE, { onCompleted: onGetTemplateCompleted });

	const [sendEmail, sendEmailQuery] = useMutation<
		OrganismsNemSendEmail,
		OrganismsNemSendEmailVariables
	>(ORGANISMS_NEM_SEND_EMAIL);

	React.useEffect(() => {
		if (props.visible) {
			if (props.id) {
				getEmail({ variables: { id: props.id } });
			}

			if (props.userDetails) {
				form.setValue('to', props.userDetails.email);
			}
		}
	}, [props.id, props.visible, props.userDetails]);

	React.useEffect(() => {
		form.trigger('to');
	}, [subjectWatch]);

	function onCompleted(data: OrganismsNemGetEmailDetails) {
		form.setValue('from', data.emails?.nodes?.[0]?.from ?? '');
		data.emails?.nodes?.[0]?.to?.split(',').forEach((x) => form.setValue('to', x));

		form.setValue('subject', data.emails?.nodes?.[0]?.subject!);
		form.setValue('template', data.emails?.nodes?.[0]?.template!);
		form.setValue('body', data?.emails?.nodes?.[0]?.body!);
	}

	async function onSubmit(data: NewEmailMessageFormData): Promise<void> {
		try {
			await sendEmail({
				variables: {
					input: {
						accountId: props.accountDetails?.id,
						userId: props.userDetails.id,
						fundingId: props.fundingId,
						subject: data.subject,
						body: data.newBody,
						template: data.template || EmailTemplateType.NONE,
						emailAddresses: [
							{
								type: EmailAddressType.TO,
								address: data.to,
							},
							{
								type: EmailAddressType.FROM,
								address: data.from,
							},
						],
					},
				},
			});

			onCloseModal();
			props.onSaved(true);
		} catch (error) {
			props.onSaved(false);
		}
	}

	function onGetTemplateCompleted(data: OrganismsNemGetTemplate): void {
		form.setValue('subject', data.emailTemplate?.data?.subject!);
		form.setValue('body', data.emailTemplate?.data?.body!);
		form.trigger();
	}

	function onCloseModal(): void {
		props.toggleVisible();
		form.reset();
	}

	function onCancel() {
		form.reset();
		props.toggleVisible();
	}

	return (
		<AppModal visible={props.visible} onBackdropPress={onCloseModal}>
			<CustomCard
				hasCloseButton
				footer={() => (
					<View style={styles.actions.container}>
						<Button
							appearance="outline"
							testID={EmailModalComponentLocators.cancelButton}
							onPress={onCancel}
						>
							Cancel
						</Button>

						{props.mode !== 'View' && (
							<Button
								accessoryRight={
									sendEmailQuery.loading
										? () => <Spinner size="tiny" status="basic" />
										: undefined
								}
								disabled={!form.formState.isValid}
								style={styles.actions.save}
								testID={EmailModalComponentLocators.saveButton}
								onPress={
									sendEmailQuery.loading ? undefined : form.handleSubmit(onSubmit)
								}
							>
								Send
							</Button>
						)}
					</View>
				)}
				handleClosePress={props.toggleVisible}
				header={props.mode ? `${props.mode} Message` : ''}
				style={styles.main.container}
			>
				<View style={styles.inputs.row}>
					<Text style={styles.inputs.label}>
						From:<Text status="danger">*</Text>
					</Text>
					<Controller
						control={form.control}
						name="from"
						render={(control) => (
							<View style={styles.inputs.control}>
								<AppSelector
									closeOnSelect
									data={fromEmails}
									disabled={props.mode === 'View'}
									placeholder="Select an email address"
									testID={EmailModalComponentLocators.fromField}
									value={control.field.value}
									onSelect={(val) => {
										control.field.onChange(val);
									}}
								/>
							</View>
						)}
					/>
				</View>

				<View style={styles.inputs.row}>
					<Text style={styles.inputs.label}>
						To:<Text status="danger">*</Text>
					</Text>
					<Controller
						control={form.control}
						name="to"
						render={(control) => (
							<Input
								disabled={props.mode === 'View'}
								placeholder="Enter an email address"
								style={styles.inputs.control}
								testID={EmailModalComponentLocators.toField}
								value={control.field.value}
								onBlur={control.field.onBlur}
								onChangeText={control.field.onChange}
							/>
						)}
					/>
				</View>

				<View style={styles.inputs.row}>
					<Text style={styles.inputs.label}>
						Choose Template:<Text status="danger">*</Text>
					</Text>
					<Controller
						control={form.control}
						name="template"
						render={(control) => (
							<View style={styles.inputs.control}>
								<AppSelector
									closeOnSelect
									data={Object.values(
										(props.accountDetails?.id
											? emailTemplatesRequiringAccountInfo.concat(
													emailTemplatesGenericInfo
											  )
											: emailTemplatesGenericInfo
										).sort()
									)}
									disabled={props.mode === 'View'}
									itemDisplay={emailTemplateTypeHumanizer}
									testID={EmailModalComponentLocators.templateField}
									value={control.field.value}
									onSelect={(templateType) => {
										getTemplate({
											variables: {
												input: {
													templateType,
													userId: props.userDetails?.id,
													accountId: props.accountDetails?.id,
												},
											},
										});
										control.field.onChange(templateType);
									}}
								/>
							</View>
						)}
					/>
				</View>

				<View style={styles.main.errorMessageContainer}>
					{getTemplateQuery?.data?.emailTemplate?.errorMessage && (
						<Text style={styles.main.errorMessage}>
							{getTemplateQuery?.data?.emailTemplate?.errorMessage}
						</Text>
					)}
					{getTemplateQuery?.data?.emailTemplate?.errorMessage?.includes(
						'Template contains account info'
					) && (
						<Text style={styles.main.errorMessage}>
							This email template requires account info, please navigate the edit
							account screen for this specific account
						</Text>
					)}
				</View>

				<View style={styles.inputs.row}>
					<Text style={styles.inputs.label}>
						Subject:<Text status="danger">*</Text>
					</Text>
					<Controller
						control={form.control}
						name="subject"
						render={(control) => (
							<Input
								disabled={props.mode === 'View'}
								style={styles.inputs.control}
								testID={EmailModalComponentLocators.subjectField}
								value={control.field.value!}
								onBlur={control.field.onBlur}
								onChange={control.field.onChange}
							/>
						)}
					/>
				</View>

				<Controller
					control={form.control}
					name="body"
					render={(control) => (
						<RichTextEditor
							readonly={props.mode === 'View'}
							value={control.field.value}
							onBlur={control.field.onBlur}
							onChange={(value) => form.setValue('newBody', value)}
							// changing body value will not update wysiwig
						/>
					)}
				/>
			</CustomCard>
		</AppModal>
	);
}

function useCustomStyles() {
	const appDevice = useAppDevice();

	return {
		main: StyleSheet.create({
			container: {
				minWidth: appDevice.isNativeMobile ? 'auto' : 1000,
				maxWidth: 1000,
			},
			header: {
				flexDirection: 'row',
				justifyContent: 'space-between',
				alignItems: 'center',
			},
			errorMessage: {
				color: 'red',
				textAlign: 'right',
			},
			errorMessageContainer: {
				marginBottom: 16,
			},
		}),
		inputs: StyleSheet.create({
			row: {
				flexDirection: 'row',
				justifyContent: 'space-between',
				alignItems: 'center',
				marginBottom: 8,
			},
			label: {
				flex: 0.3,
			},
			control: {
				flex: 0.7,
			},
		}),
		actions: StyleSheet.create({
			container: {
				justifyContent: 'flex-end',
				flexDirection: 'row',
			},
			save: {
				marginLeft: 8,
			},
		}),
	};
}
