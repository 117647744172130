import { gql, useQuery } from '@apollo/client';
import {
	EcsGetUserDetails,
	EcsGetUserDetails_users_nodes_accounts as Account,
	EcsGetUserDetailsVariables,
} from '@app/codegen';
import { useMaestroUserAtom } from '@app/core/atoms';
import { EditCustomerScreenParams, useCustomersNavigation } from '@app/navigations/Main/Customers';
import {
	CustomerInformation,
	Documents,
	Emails,
	FundingsCard,
	NavConfigOptions,
	Notes,
	StickyNav,
	TopHeader,
	UserAuditHistoryCardOrganism,
} from '@app/shared/components';
import { findSectionHelper } from '@app/shared/helpers';
import {
	AppIcon,
	AppScreen,
	ScreenSizeEnum,
	useAppDevice,
	useAppState,
	useNavigationParams,
} from '@itrustcapital/ui';
import React from 'react';
import { ScrollView, StyleSheet, View } from 'react-native';

import { Astra } from './Astra';
import { CustomerAccounts } from './CustomerAccounts';
import { CustomerBeneficaries } from './CustomerBeneficaries';
import { ExternalNotifications } from './ExternalNotifications';
import { KYC } from './KYC';
import { SecurityQuestions } from './SecurityQuestions';
import { TrustPilotCard } from './TrustPilotCard';

export enum EditCustomerSectionLabel {
	Information = 'INFORMATION',
	Documents = 'DOCUMENTS',
	Fundings = 'FUNDINGS',
	Beneficaries = 'BENEFICIARIES',
	Accounts = 'ACCOUNTS',
	EmailsAndTickets = 'EMAILS_&_TICKETS',
	Notes = 'NOTES',
	ExternalNotifications = 'EXTERNAL_NOTIFICATIONS',
	TrustPilotInvitations = 'TRUST_PILOT_INVITATIONS',
	KYC = 'KYC',
	Astra = 'Astra',
	SecurityQuestions = 'SECURITY_QUESTIONS',
	UserAuditHistory = 'USER_HISTORY',
}

export interface EditCustomerScreenNavOptions extends NavConfigOptions<EditCustomerSectionLabel> {}

export const ECS_GET_USER_DETAILS = gql`
	query EcsGetUserDetails($where: UserFilterInput!) {
		users(where: $where, first: 1) {
			nodes {
				id
				fortressIdentityId
				firstName
				lastName
				fullName
				middleName
				dateOfBirth
				socialSecurityNumber
				isVip
				addresses {
					address
					address2
					city
					state
					zipCode
					country
					type
				}
				email
				phone
				enableNotifications
				status
				accounts {
					id
					type
					status
					accountNumber
					inboundFundings {
						id
						fundingAssets {
							id
						}
					}
					sourceOfFunds
					accountPurpose
					outboundFundings {
						id
						fundingAssets {
							id
						}
					}
				}
				priorNames
				employerName
				jobTitle
				employmentStatus
				beneficiaries {
					id
					accountBeneficiaries {
						id
					}
				}
				documents {
					id
				}
				securityAnswers {
					id
					securityQuestion {
						text
					}
					text
				}
			}
		}
	}
`;

export function EditCustomerScreen() {
	const styles = useCustomStyles();
	const customerNavigation = useCustomersNavigation();
	const navigationParams = useNavigationParams<EditCustomerScreenParams>('Edit');
	const selectedSection = useAppState(EditCustomerSectionLabel.Information);
	const userId = Number(navigationParams?.customerId!);
	const maestroUserAtom = useMaestroUserAtom();

	const getUserDetailsQuery = useQuery<EcsGetUserDetails, EcsGetUserDetailsVariables>(
		ECS_GET_USER_DETAILS,
		{
			variables: {
				where: {
					id: {
						eq: userId,
					},
				},
			},
			fetchPolicy: 'network-only',
			skip: !userId,
		}
	);

	const [user] = getUserDetailsQuery.data?.users?.nodes || [];

	const accounts = user?.accounts;
	const accountIds = accounts?.map((x) => x?.id);

	const NavSections: {
		[K in EditCustomerSectionLabel]: EditCustomerScreenNavOptions;
	} = {
		[EditCustomerSectionLabel.Information]: {
			ref: React.useRef<HTMLElement & View>(null),
			label: EditCustomerSectionLabel.Information,
			iconLib: 'fe',
			iconName: 'search',
		},
		[EditCustomerSectionLabel.Astra]: {
			label: EditCustomerSectionLabel.Astra,
			defaultLabelValue: true,
			ref: React.useRef<HTMLElement & View>(null),
			iconLib: 'fe',
			iconName: 'zap',
		},
		[EditCustomerSectionLabel.KYC]: {
			label: EditCustomerSectionLabel.KYC,
			defaultLabelValue: true,
			ref: React.useRef<HTMLElement & View>(null),
			iconLib: 'fa5',
			iconName: 'id-badge',
		},
		[EditCustomerSectionLabel.Documents]: {
			ref: React.useRef<HTMLElement & View>(null),
			label: EditCustomerSectionLabel.Documents,
			iconLib: 'fa5',
			iconName: 'folder-open',
		},
		[EditCustomerSectionLabel.Fundings]: {
			ref: React.useRef<HTMLElement & View>(null),
			label: EditCustomerSectionLabel.Fundings,
			iconLib: 'ion',
			iconName: 'ios-cash-outline',
		},
		[EditCustomerSectionLabel.Beneficaries]: {
			ref: React.useRef<HTMLElement & View>(null),
			label: EditCustomerSectionLabel.Beneficaries,
			iconLib: 'mat',
			iconName: 'people-outline',
		},
		[EditCustomerSectionLabel.Accounts]: {
			ref: React.useRef<HTMLElement & View>(null),
			label: EditCustomerSectionLabel.Accounts,
			iconLib: 'ion',
			iconName: 'ios-cash-outline',
		},
		[EditCustomerSectionLabel.TrustPilotInvitations]: {
			ref: React.useRef<HTMLElement & View>(null),
			label: EditCustomerSectionLabel.TrustPilotInvitations,
			iconLib: 'ant',
			iconName: 'star',
		},
		[EditCustomerSectionLabel.EmailsAndTickets]: {
			ref: React.useRef<HTMLElement & View>(null),
			label: EditCustomerSectionLabel.EmailsAndTickets,
			iconLib: 'ant',
			iconName: 'linechart',
		},
		[EditCustomerSectionLabel.Notes]: {
			ref: React.useRef<HTMLElement & View>(null),
			label: EditCustomerSectionLabel.Notes,
			iconLib: 'fe',
			iconName: 'file-text',
		},
		[EditCustomerSectionLabel.ExternalNotifications]: {
			ref: React.useRef<HTMLElement & View>(null),
			label: EditCustomerSectionLabel.ExternalNotifications,
			iconLib: 'ant',
			iconName: 'message1',
		},
		[EditCustomerSectionLabel.SecurityQuestions]: {
			ref: React.useRef<HTMLElement & View>(null),
			label: EditCustomerSectionLabel.SecurityQuestions,
			iconLib: 'ant',
			iconName: 'question',
		},
		[EditCustomerSectionLabel.UserAuditHistory]: {
			ref: React.useRef<HTMLElement & View>(null),
			label: EditCustomerSectionLabel.UserAuditHistory,
			iconLib: 'fe',
			iconName: 'user-check',
		},
	};

	const handleScroll = React.useCallback(
		findSectionHelper<EditCustomerScreenNavOptions>(Object.values(NavSections), (section) =>
			selectedSection.set(section.label)
		),
		[]
	);

	return (
		<AppScreen noMarginBottom noMaxWidth noPadding noScroll style={styles.main.container}>
			<TopHeader
				goBack={() => customerNavigation.Home(false)}
				subTitle="Create or edit an IRA Customer"
				title="Create / Edit Customer"
			>
				<View style={styles.main.vip}>
					{user?.isVip && <AppIcon lib="fe" name="star" size={24} />}
				</View>
			</TopHeader>
			<View style={styles.main.subContainer}>
				<StickyNav
					data={Object.values(NavSections)}
					selectedSection={selectedSection.get}
				/>
				<View style={styles.main.content}>
					<ScrollView scrollEventThrottle={16} onScroll={handleScroll}>
						{/* Customer Information */}
						<View ref={NavSections.INFORMATION.ref} style={styles.main.card}>
							<CustomerInformation
								loading={getUserDetailsQuery.loading}
								userInfo={user}
							/>
						</View>

						{/* Astra */}
						<View ref={NavSections.Astra.ref} style={styles.main.card}>
							<Astra userId={userId} />
						</View>

						{/* KYC */}
						<View ref={NavSections.KYC.ref} style={styles.main.card}>
							<KYC userId={userId} />
						</View>

						{/* Documents */}
						<View ref={NavSections.DOCUMENTS.ref} style={styles.main.card}>
							<Documents accountDetails={accounts?.[0] as Account} userId={userId} />
						</View>

						{/* Fundings */}
						<View ref={NavSections.FUNDINGS.ref} style={styles.main.card}>
							<FundingsCard isCustomer accountIds={accountIds as number[]} />
						</View>

						{/* Beneficiaries */}
						<View ref={NavSections.BENEFICIARIES.ref} style={styles.main.card}>
							<CustomerBeneficaries userId={userId} />
						</View>

						{/* Accounts */}
						<View ref={NavSections.ACCOUNTS.ref} style={styles.main.card}>
							<CustomerAccounts
								userEmail={user?.email!}
								userId={userId}
								userName={user?.fullName!}
							/>
						</View>

						{/* Trust Pilot Invitations */}
						<View
							ref={NavSections.TRUST_PILOT_INVITATIONS.ref}
							style={styles.main.card}
						>
							<TrustPilotCard userId={userId} />
						</View>

						{/* Emails */}
						{user && (
							<View
								ref={NavSections[EditCustomerSectionLabel.EmailsAndTickets].ref}
								style={styles.main.card}
							>
								<Emails userDetails={user} />
							</View>
						)}

						{/* Notes */}
						<View ref={NavSections.NOTES.ref} style={styles.main.card}>
							<Notes userId={userId} />
						</View>

						<View ref={NavSections.EXTERNAL_NOTIFICATIONS.ref} style={styles.main.card}>
							<ExternalNotifications userId={userId} />
						</View>

						{/* Security Questions */}
						<View ref={NavSections.SECURITY_QUESTIONS.ref} style={styles.main.card}>
							<SecurityQuestions userInfo={user} />
						</View>

						{maestroUserAtom?.isCrmUser && (
							<View ref={NavSections.USER_HISTORY.ref} style={styles.main.card}>
								<UserAuditHistoryCardOrganism userId={userId} />
							</View>
						)}
					</ScrollView>
				</View>
			</View>
		</AppScreen>
	);
}

function useCustomStyles() {
	const appDevice = useAppDevice();

	const isScreenMd = appDevice.width >= ScreenSizeEnum.md;

	return {
		main: StyleSheet.create({
			container: {
				flexDirection: 'column',
			},
			subContainer: {
				flexDirection: isScreenMd ? 'row' : 'column',
				flex: 1,
				padding: 32,
			},
			content: {
				flex: 1,
			},
			card: {
				marginBottom: 8,
			},
			vip: {
				flex: 1,
				flexDirection: 'row-reverse',
				marginRight: 12,
			},
		}),
	};
}
