import { gql, useLazyQuery, useMutation } from '@apollo/client';
import {
	OrganismsSpGenerate,
	OrganismsSpGenerateVariables,
	OrganismsSpGetUser,
	OrganismsSpGetUserVariables,
} from '@app/codegen';
import { CustomCard } from '@app/shared/components';
import { localDateTimeFormat, useAppTheme } from '@itrustcapital/ui';
import { Button, Text } from '@ui-kitten/components';
import React from 'react';
import { StyleSheet, View } from 'react-native';
import Toast from 'react-native-toast-message';

export interface ServicePinProps {
	userId?: number;
}

export const ORGANISMS_SP_GENERATE = gql`
	mutation OrganismsSpGenerate($input: GenerateServicePinInput!) {
		generateServicePin(input: $input) {
			success
		}
	}
`;

export const ORGANISMS_SP_GET_USER = gql`
	query OrganismsSpGetUser($where: UserFilterInput!) {
		users(where: $where, first: 1) {
			nodes {
				servicePin
				servicePinGeneratedAt
			}
		}
	}
`;

export function ServicePin(props: ServicePinProps) {
	const styles = useCustomStyles();

	const [generateServicePin] = useMutation<OrganismsSpGenerate, OrganismsSpGenerateVariables>(
		ORGANISMS_SP_GENERATE,
		{
			variables: {
				input: {
					userId: props.userId!,
				},
			},
			onCompleted: (data) => {
				if (data.generateServicePin?.success) {
					Toast.show({ type: 'success', text2: 'Servic Pin Generated' });
					getUser();
				} else {
					Toast.show({ type: 'error', text2: 'Servic Pin Generation Failed' });
				}
			},
		}
	);

	const [getUser, getUserQuery] = useLazyQuery<OrganismsSpGetUser, OrganismsSpGetUserVariables>(
		ORGANISMS_SP_GET_USER,
		{
			variables: {
				where: { id: { eq: props.userId! } },
			},
			fetchPolicy: 'no-cache',
		}
	);

	const [user] = getUserQuery.data?.users?.nodes || [];

	const servicePin = user?.servicePin;

	return (
		<CustomCard
			header={() => (
				<View style={styles.main.header}>
					<Text category="h6">Service Pin</Text>
					<View style={styles.main.actions}>
						<Button onPress={() => generateServicePin()}>New Service Pin</Button>
					</View>
				</View>
			)}
		>
			<View style={styles.main.pinContainer}>
				<Text category="s1">{`Pin: ${servicePin ? servicePin : '******'}`}</Text>
				<Text category="s1">
					{`Generated At: ${localDateTimeFormat(
						user?.servicePinGeneratedAt,
						'MM/DD/YYYY, HH:MM'
					)}`}
				</Text>
			</View>
		</CustomCard>
	);
}

function useCustomStyles() {
	const theme = useAppTheme();

	return {
		main: StyleSheet.create({
			header: {
				flexDirection: 'row',
				alignItems: 'center',
			},
			actions: {
				flexDirection: 'row',
				justifyContent: 'flex-end',
				flex: 1,
			},
			pinContainer: {
				flexDirection: 'row',
				alignItems: 'center',
				justifyContent: 'space-around',
				backgroundColor: theme['color-basic-transparent-300'],
				borderRadius: 8,
				paddingVertical: 16,
			},
		}),
	};
}
